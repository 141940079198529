import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AppModulesType } from '../../../../../../_shared/enums/appModulesType.enum';
import { DocumentSign } from '../../../../../../_shared/model/portfolio/documentSign.model';
import { PortfolioPaper } from '../../../../../../_shared/model/portfolio/portfolioPaper.model';
import { DocuVersion } from '../../../../../../_shared/model/portfolio/version.model';
import { Case } from '../../../../../../_shared/model/schedule/case.model';
import { OpenDocumentRequest } from '../../../../../../_shared/model/utils/openDocumentRequest.model';
import { ActiveModulesService } from '../../../../../../_shared/services/activeModules.service';
import { HelperService } from '../../../../../../_shared/services/helper.service';
import { StorageService } from '../../../../../../_shared/services/storage.service';
import { ResultService } from '../../../_services/result.service';
import { PfPCommnComunicationService } from './../../papers/_common/pf-p-comn-communication.service';

@Component({
    selector: 'pf-comn-versions-list',
    templateUrl: './pf-comn-versions-list.component.html',
    styles: [],
})
export class PfComnVersionsListComponent implements OnInit {
    constructor(
        public activeModal: NgbActiveModal,
        private comm: PfPCommnComunicationService,
        private rest: ResultService,
        private storage: StorageService,
        private helper: HelperService,
        private toast: ToastrService,
        private activeModules: ActiveModulesService,
    ) {}

    @Input()
    set setPaper(paper: PortfolioPaper) {
        if (paper) {
            this.paper = paper;
        }
    }

    @Input()
    set setCase(caseData: Case) {
        if (caseData) {
            this.case = caseData;
        }
    }
    public paper: PortfolioPaper;
    public case: Case;
    private onDestroy$ = new Subject<void>();
    public itemsPerPage = 3;
    public numberOfItems = 0;
    public currentPage = 1;
    public allVersions: DocuVersion[] = [];
    public isCaseActive = false;

    ngOnInit() {
        this.initData();
        this.comm.onSignDocumentExecuted.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
            //update data ko podpišejo dokument
            this.initData();
        });
        this.comm.onPaperChanged.pipe(takeUntil(this.onDestroy$)).subscribe((paper: PortfolioPaper) => {
            this.paper = paper;
        });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    public initData() {
        if (_.get(this.paper, 'id')) {
            this.rest
                .getAllSignedFromEa(
                    this.storage.getSelectedContractor().id,
                    _.get(this.paper, 'id'),
                    _.get(this.case, 'bsid'),
                    _.get(this.case, 'caseId'),
                )
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(versions => {
                    this.allVersions = versions;
                    this.numberOfItems = _.get(versions, 'length', 0);
                });
        }
        this.isCaseActive = this.activeModules.isAM(AppModulesType.CASE);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public previewDocument(item: any) {
        this.rest
            .getSingleSigned(this.storage.getSelectedContractor().id, item.documentId)
            .pipe(
                takeUntil(this.onDestroy$),
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                map((res: any) => {
                    if (res.type !== 0) {
                        const signer: string = res.headers.get('X-SIGNATURE-SIGNER');
                        const date: string = res.headers.get('X-SIGNATURE-DATE');
                        const contractor: string = res.headers.get('X-SIGNATURE-IZS');
                        const signature: DocumentSign = new DocumentSign().deserialize({
                            signer: signer,
                            date: date,
                            contractor: contractor,
                        });
                        const document: Blob = new Blob([res.body], {
                            type: res.body.type,
                        });
                        return new OpenDocumentRequest().deserialize({ document: document, signature: signature });
                    }
                }),
            )
            .subscribe(
                res => {
                    if (res) {
                        this.helper.openDocumentViewerSimple(res, item.description);
                    }
                },
                () => {
                    this.toast.error('Napaka pri predogledu.');
                },
            );
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public appendDocumentToAuth(item: any) {
        this.comm.appendSavedDocumentToCase(this.case, item.documentId).subscribe(
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            res => {
                item.caseDocument = true;
                this.toast.success('Dokument uspešno povezan');
            },
            () => {
                this.toast.error('Napaka pri povezovanju.');
            },
        );
    }
}
