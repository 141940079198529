import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { AgoraClient, ClientEvent, NgxAgoraService, Stream, StreamEvent } from 'ngx-agora';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AppModulesType } from '../../../_shared/enums/appModulesType.enum';
import { HelperService, MfToastService, StorageService } from '../../../_shared/services';
import { ActiveModulesService } from '../../../_shared/services/activeModules.service';
import { FullScreenService } from '../../../_shared/services/full-screen.service';
import { RxWebsocketSubject } from '../../../_shared/services/rxWebsocketSubject';
import { CallStateEnum } from './call-state.enum';
import { FileSource } from './enums/file-source.enum';
import { WsMessageType } from './enums/ws-message-type.enum';
import { DocumentData } from './model/document-data.model';
import { WebSocketChannel } from './model/webSocketChannel.model';
import { WebSocketChannelInfo } from './model/webSocketChannelInfo.model';
import { WsMessage } from './model/ws-message.model';

@Injectable({
    providedIn: 'root',
})
export class TelemedicineService {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public appId: FormControl = new FormControl((environment as any).agora ? (environment as any).agora.appId : '');
    public localAvalaible: BehaviorSubject<string> = new BehaviorSubject('WAITING');
    public patientReady: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public outsideCallInProgress$: BehaviorSubject<WebSocketChannel> = new BehaviorSubject(null);

    public activeVideoDevice$: BehaviorSubject<MediaStreamTrack> = new BehaviorSubject(null);
    public activeAudioDevice$: BehaviorSubject<MediaStreamTrack> = new BehaviorSubject(null);
    public activePlayoutDevice$: BehaviorSubject<MediaDeviceInfo> = new BehaviorSubject(null);

    public videoDevices$: BehaviorSubject<MediaDeviceInfo[]> = new BehaviorSubject([]);
    public audioDevices$: BehaviorSubject<MediaDeviceInfo[]> = new BehaviorSubject([]);
    public playoutDevices$: BehaviorSubject<MediaDeviceInfo[]> = new BehaviorSubject([]);

    public localAudioEnabled = null;
    public localVideoEnabled = null;
    public localAudioEnabled$: BehaviorSubject<boolean> = new BehaviorSubject(null);
    public localVideoEnabled$: BehaviorSubject<boolean> = new BehaviorSubject(null);
    public audioVolume = 100;
    public audioVolume$: BehaviorSubject<number> = new BehaviorSubject(100);

    private localStream: Stream;
    private remoteStream: Stream;
    private client: AgoraClient;

    public remoteClients: WebSocketChannel[] = [];
    public doctorClients: WebSocketChannel[] = [];

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public peers: any[] = [];

    public callState: CallStateEnum = CallStateEnum.NONE; //0 - Joined, 1 - Joined, not published, 2 - Published, in call, 3 - unpublished, leaving, 4 - ended
    private callState$: BehaviorSubject<CallStateEnum> = new BehaviorSubject(CallStateEnum.NONE);
    private currentCallChannel$: BehaviorSubject<null | string> = new BehaviorSubject(null);

    public windowSize = 'modal';
    public windowSize$: BehaviorSubject<string> = new BehaviorSubject('modal');
    public windowPosition = 'bottom-right';
    public windowPosition$: BehaviorSubject<string> = new BehaviorSubject('bottom-right');

    public channel: string;
    private uid: number;
    private token: string;

    private telemedInitialised = false;

    public isTelemedicineModuleActive = false;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public chatDataFromOtherComponents: Subject<any> = new Subject();
    //private activeWS: WebSocketSubject<any>[] = [];
    //private channelData: WebSocketChannel[] = [];

    private channels: {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        activeWS: RxWebsocketSubject<any>;
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        activeWSSubscription: Observable<any>;
        channelData: WebSocketChannel;
        remoteClients: WebSocketChannel;
        doctorClients: WebSocketChannel;
        patientReady: BehaviorSubject<{ status: boolean; description: string }>;
        channelInfo: WebSocketChannelInfo;
        channelInfo$: BehaviorSubject<WebSocketChannelInfo>;
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        chatData$: BehaviorSubject<any>;
    }[] = [];

    constructor(
        private agoraService: NgxAgoraService,
        private fs: FullScreenService,
        private mfToast: MfToastService,
        private am: ActiveModulesService,
        private http: HttpClient,
        private helper: HelperService,
        private storage: StorageService,
    ) {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        window.onbeforeunload = e => {
            for (const key in this.channels) {
                this.closeTelemed(key, true);
            }
        };
    }

    public getUserData() {
        const user = this.storage.getUserData();
        let sessionId = this.storage.getSocketSessionId();
        if (!sessionId) {
            sessionId = Math.random().toString();
            this.storage.setSocketSessionId(sessionId);
        }
        let userName = user.username ? user.username : user.email;
        userName = userName.replace(/[^a-z0-9]/gi, '_');
        const userId = userName + '-' + sessionId;

        let displayName = '';
        if (user.name) {
            displayName += user.name;
        }

        if (displayName !== '' && user.surname) {
            displayName += ' ';
        }

        if (user.surname) {
            displayName += user.surname;
        }

        if (displayName === '') {
            displayName = user.username ? user.username : user.email;
        }

        return {
            userName: userName,
            userId: userId,
            displayName: displayName,
        };
    }

    public initChannelData(channelId: string) {
        if (!this.channels[channelId]) {
            this.channels[channelId] = {
                activeWS: null,
                activeWSSubscription: null,
                channelInfo: new WebSocketChannelInfo().deserialize({}),
                channelData: new WebSocketChannel().deserialize({
                    userName: this.getUserData().userName,
                    userId: this.getUserData().userId,
                    displayName: this.getUserData().displayName,
                }),
                remoteClients: [],
                doctorClients: [],
                patientReady: new BehaviorSubject({ status: false, description: null }),
                channelInfo$: new BehaviorSubject(null),
                chatData$: new BehaviorSubject(null),
            };
        }
    }

    public closeTelemed(channelId: string, force: boolean = false) {
        if (this.channel !== channelId || force) {
            this.channels[channelId].activeWSSubscription.unsubscribe();
            this.channels[channelId].activeWS.close();
            delete this.channels[channelId];
        }
    }

    public closeAllTelemed(): void {
        for (const key of Object.keys(this.channels)) {
            this.closeTelemed(key);
        }
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getChatData(channelId: string): Observable<any> {
        // const channelId: string = ''; //this.storage.getChannelId();
        if (!channelId) {
            return of();
        }
        return this.channels[channelId]?.chatData$;
    }

    private startSocketConnection(channelId: string) {
        if (!channelId) {
            return;
        }

        if (!this.channels[channelId].activeWS) {
            this.channels[channelId].activeWS = new RxWebsocketSubject(
                this.helper.getWebSocketTelemedUrl() +
                    `${channelId}/${this.getUserData().userId}/${this.storage.getUserData().id}?auth_token=${this.storage.getToken().access_token}`,
                {
                    subMsg: () =>
                        this.createSocketMessage(channelId, {
                            //userName: this.channels[channelId].channelData.userName,
                            //displa: this.channels[channelId].channelData.userName,
                            onTerm: true,
                        }),
                    unsubMsg: () =>
                        new WebSocketChannel().deserialize({
                            userName: this.channels[channelId].channelData.userName,
                            userId: this.channels[channelId].channelData.userId,
                            displayName: this.channels[channelId].channelData.displayName,
                        }),
                    // TODO Ignored with eslint-interactive on 2023-11-10
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    messageFilter: (message: any) => {
                        if (_.isEmpty(message)) {
                            return false;
                        }

                        if (message.eventType == 'chat') {
                            return true;
                        }

                        if (message.eventType) {
                            return false;
                        }

                        if (!message.doctorStartTime && !message.patientStartTime && !message.userId) {
                            return false;
                        }
                        return true;
                    },
                },
            );

            this.channels[channelId].activeWSSubscription = this.channels[channelId].activeWS
                .pipe(
                    // TODO Ignored with eslint-interactive on 2023-11-10
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    map((message: any) => {
                        if (message.doctorStartTime || message.patientStartTime) {
                            return {
                                messageType: 'channelInfo',
                                data: message,
                            } as {
                                messageType: string;
                                data: WebSocketChannelInfo;
                            };
                        } else if (message.userId) {
                            return {
                                messageType: 'userInfo',
                                data: message,
                            } as {
                                messageType: string;
                                data: WebSocketChannel;
                            };
                        } else if (message.eventType == 'chat') {
                            return new WsMessage().deserialize({
                                messageType: WsMessageType.CHAT_DATA,
                                data: _.get(message, 'chat', []),
                            });
                        } else {
                            return null;
                            console.error('Unknown message from socket');
                        }
                    }),
                )
                .subscribe((message: { messageType: string; data: WebSocketChannelInfo | WebSocketChannel }) => {
                    if (message.messageType === 'channelInfo') {
                        this.channels[channelId].channelInfo = new WebSocketChannelInfo().deserialize(message.data);
                        this.channels[channelId].channelInfo$.next(this.channels[channelId].channelInfo);
                    }

                    if (message.messageType === 'userInfo') {
                        if ((message.data as WebSocketChannel).userId === this.channels[channelId].channelData.userId) {
                            return;
                        }

                        if (this.isPatient(message.data as WebSocketChannel)) {
                            if ((message.data as WebSocketChannel).onTerm) {
                                this.addPatient(channelId, message.data);
                            } else {
                                this.removePatient(channelId, message.data);
                            }
                        } else {
                            if ((message.data as WebSocketChannel).onTerm) {
                                this.addDoctor(channelId, message.data as WebSocketChannel);
                            } else {
                                this.removeDoctor(channelId, message.data);
                            }
                        }
                    }

                    if (message.messageType === WsMessageType.CHAT_DATA) {
                        this.channels[channelId].chatData$.next(message.data);
                    }
                });

            // @TODO Clear intervals na leave
            setInterval(() => {
                if (this.callState === CallStateEnum.CALL_STARTED && this.channel) {
                    this.sendSocketAddTimeMessage(this.channel);
                }
            }, 60000);
        }
    }

    public getConnectionStatus(channelId: string) {
        if (!channelId) {
            console.log('ni channel idja');
            return;
        }

        if (this.channels[channelId] && this.channels[channelId].activeWS) {
            return this.channels[channelId].activeWS.connectionStatus;
        } else {
            console.log('ni wsja');
        }
    }

    public initTelemed(channelId: string) {
        if (!channelId) {
            return;
        }
        this.initChannelData(channelId);

        this.startSocketConnection(channelId);
    }

    public sendSocketPingMessage(channelId: string) {
        this.sendSocketMessage(channelId);
    }

    public sendSocketAddTimeMessage(channelId: string) {
        if (this.channels[channelId]) {
            this.channels[channelId].activeWS.send({
                eventType: 'ADDTIME',
                source: 'DOCTOR',
            });
        }
    }

    public sendChatSocketOuterComponent(channelId: string, message: string, documents?: DocumentData[], fileSource?: FileSource): void {
        //direktno pripenjanje dokumentov iz portfolio-list.component.ts ALI document-viewer.component.ts v telm-chat.component.ts
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: any[] = [
            {
                eventType: 'chat',
                source: 'DOCTOR',
                chat: message,
            },
        ];
        documents.forEach((document, index) => {
            if (index == 0) {
                data[0].document = { ...document, fileSource: fileSource };
            } else {
                data.push({
                    eventType: 'chat',
                    source: 'DOCTOR',
                    chat: null,
                    document: { ...document, fileSource: fileSource },
                });
            }
        });
        if (this.channels[channelId]) {
            const channel = this.channels[channelId].activeWS;
            if (channel) {
                channel.send(data);
            }
            this.chatDataFromOtherComponents.next(data);
        } else {
            console.error(`Error sending message to socket (generic) ${channelId}`);
        }
    }

    public sendChatSocketMessage(channelId: string, message: string, documents?: DocumentData[]): void {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: any[] = [
            {
                eventType: 'chat',
                source: 'DOCTOR',
                chat: message,
            },
        ];
        // if (documents) {
        //     data.document = document;
        // }

        documents.forEach((document, index) => {
            if (index == 0) {
                data[0].document = document;
            } else {
                data.push({
                    eventType: 'chat',
                    source: 'DOCTOR',
                    chat: null,
                    document: document,
                });
            }
        });
        if (this.channels[channelId]) {
            const channel = this.channels[channelId].activeWS;
            if (channel) {
                channel.send(data);
                // this.channels[channelId].chatData$.next(data);
            }
        } else {
            console.error(`Error sending message to socket (generic) ${channelId}`);
        }
    }

    public sendChatSocketMessageOld(channelId: string, message: string, document?: DocumentData[]): void {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: any = {
            eventType: 'chat',
            source: 'DOCTOR',
            chat: message,
        };
        if (document) {
            data.document = document;
        }
        if (this.channels[channelId]) {
            const channel = this.channels[channelId].activeWS;
            if (channel) {
                channel.send(data);
            }
        } else {
            console.error(`Error sending message to socket (generic) ${channelId}`);
        }
    }

    public sendSocketStartMessage(channelId: string) {
        if (this.channels[channelId]) {
            this.channels[channelId].activeWS.send({
                eventType: 'start',
                source: 'DOCTOR',
                videoSession: true,
                recordedSession: false,
            });

            if (!this.channels[channelId].channelInfo || !this.channels[channelId].channelInfo.doctorStartTime) {
                this.channels[channelId].channelInfo = new WebSocketChannelInfo().deserialize({
                    doctorStartTime: new Date().getTime(),
                });
                this.channels[channelId].channelInfo$.next(this.channels[channelId].channelInfo);
            }

            setTimeout(() => {
                this.sendSocketAddTimeMessage(channelId);
            }, 5000);
        }
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public createSocketMessage(channelId: string, data: any) {
        if (!this.channels[channelId].channelData) {
            this.channels[channelId].channelData = new WebSocketChannel().deserialize(data);
            //this.channelData[channelId].userName = user.username;
        } else {
            this.channels[channelId].channelData = {
                ...this.channels[channelId].channelData,
                ...{ timestamp: new Date() },
                ...data,
            };
        }

        return this.channels[channelId].channelData;
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public sendSocketMessage(channelId: string, data: any = {}) {
        if (this.channels[channelId]) {
            const channel = this.channels[channelId].activeWS;

            const message = this.createSocketMessage(channelId, data);

            if (channel) {
                channel.send(message);
            }
        } else {
            console.error(`Error sending message to socket ${channelId}`);
        }
    }

    public initAgora(channelId: string): Observable<boolean> {
        const response: ReplaySubject<boolean> = new ReplaySubject(1);
        this.isTelemedicineModuleActive = this.am.isAM(AppModulesType.TELEMEDICINE);
        if (!this.isTelemedicineModuleActive) {
            response.error(false);
            response.complete();
            return response;
        }

        if (this.telemedInitialised) {
            response.next(true);
            response.complete();
            return response;
        }

        this.agoraService.AgoraRTC.Logger.setLogLevel(environment.agora.logLevel);

        //this.channel = channelId;
        //this.currentCallChannel$.next(this.channel);

        const contractor = this.storage.getSelectedContractor();
        this.http
            .post(`${this.helper.getTelemedUrl()}/contractors/${contractor.id}/prereservations/${channelId}`, {
                appId: environment.agora.appId,
                isDoctor: true,
            })
            .pipe(
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                tap((result: any) => {
                    this.token = result.token;
                    this.uid = result.uid;
                    /*this.uid = this.storage.getUserData().id;
                    this.token = null;*/
                }),
            )
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .subscribe(result => {
                //const userId = Math.floor(Math.random() * 100);
                if (!this.client) {
                    this.client = this.agoraService.createClient({ mode: 'rtc', codec: 'vp8' });
                    this.assignClientHandlers();

                    this.client.init(
                        this.appId.value,
                        () => {
                            console.log('Initialized successfully');
                            response.next(true);
                            response.complete();
                            //this.getCameras();
                            //this.initLocalMedia();
                        },
                        () => {
                            console.log('Could not initialize');
                            response.error(false);
                            response.complete();
                        },
                    );
                } else {
                    response.next(true);
                    response.complete();
                }

                this.telemedInitialised = true;
            });
        return response;
    }

    private loadCameras(): void {
        if (this.client) {
            this.client.getCameras((devices: MediaDeviceInfo[]) => {
                const videoDevices = devices.filter(device => {
                    return device.deviceId !== 'default';
                });
                this.videoDevices$.next(videoDevices);
            });
        }
    }

    public getCameras(): Observable<MediaDeviceInfo[]> {
        this.loadCameras();
        return this.videoDevices$;
    }

    private loadRecordingDevice(): void {
        if (this.client) {
            this.client.getRecordingDevices((devices: MediaDeviceInfo[]) => {
                const audioDevices = devices.filter(device => {
                    return device.deviceId !== 'default';
                });
                this.audioDevices$.next(audioDevices);
            });
        }
    }

    public getRecordingDevices(): Observable<MediaDeviceInfo[]> {
        this.loadRecordingDevice();
        return this.audioDevices$;
    }

    private loadPlayoutDevices(): void {
        if (this.client) {
            this.client.getPlayoutDevices((devices: MediaDeviceInfo[]) => {
                const playoutDevices = devices.filter(device => {
                    return device.deviceId !== 'default';
                });

                if (playoutDevices.length === 0) {
                    playoutDevices.push({
                        deviceId: 'default-none',
                        groupId: null,
                        kind: 'audiooutput',
                        label: 'Zvočniki/Slušalke',
                    } as MediaDeviceInfo);
                }

                this.playoutDevices$.next(playoutDevices);
            });
        }
    }

    public getPlayoutDevices(): Observable<MediaDeviceInfo[]> {
        this.loadPlayoutDevices();
        return this.playoutDevices$;
    }

    public checkCallSettings(channelId: string) {
        this.initAgora(channelId).subscribe(
            () => {
                this.setCallState(CallStateEnum.DEVICE_TEST);
                this.initLocalMedia();
                this.localStream.init(
                    () => {
                        // The user has granted access to the camera and mic.
                        console.log('getUserMedia successfully');
                        this.localStream.play('agora-local-video');
                        this.localAvalaible.next('READY');
                        if (this.localVideoEnabled === null) {
                            this.toggleVideo(true);
                        } else if (this.localVideoEnabled === false) {
                            this.toggleVideo(false);
                        }

                        if (this.localAudioEnabled === null) {
                            this.toggleAudio(true);
                        } else if (this.localAudioEnabled === false) {
                            this.toggleAudio(false);
                        }
                        //this.connected = true;

                        this.sendSocketMessage(this.channel, {
                            cameraReady: true,
                            microphoneReady: true,
                        });
                    },
                    // TODO Ignored with eslint-interactive on 2023-11-10
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (err: any) => {
                        this.localAvalaible.next(err.msg);
                        this.sendSocketMessage(this.channel, {
                            cameraReady: false,
                            microphoneReady: false,
                        });
                        console.log('getUserMedia failed', err);
                    },
                );
            },
            () => {
                console.error('Agora init error');
            },
        );
    }

    public initCall(channelId: string, forceState?: CallStateEnum) {
        this.sendSocketMessage(channelId, {
            videoModalOpened: true,
        });
        this.initAgora(channelId).subscribe(() => {
            this.channel = channelId;
            this.currentCallChannel$.next(this.channel);
            this.setCallState(forceState ? forceState : CallStateEnum.CALL_STARTING);
            this.initLocalMedia();
            this.localStream.init(
                () => {
                    // The user has granted access to the camera and mic.
                    console.log('getUserMedia successfully');
                    this.localStream.play('agora-local-video');
                    this.localAvalaible.next('READY');
                    if (this.localVideoEnabled === null) {
                        this.toggleVideo(true);
                    } else if (this.localVideoEnabled === false) {
                        this.toggleVideo(false);
                    }

                    if (this.localAudioEnabled === null) {
                        this.toggleAudio(true);
                    } else if (this.localAudioEnabled === false) {
                        this.toggleAudio(false);
                    }

                    this.sendSocketMessage(this.channel, {
                        cameraReady: true,
                        microphoneReady: true,
                    });
                    //this.connected = true;
                },
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (err: any) => {
                    this.localAvalaible.next(err.msg);
                    this.sendSocketMessage(this.channel, {
                        cameraReady: false,
                        microphoneReady: false,
                    });
                    console.log('getUserMedia failed', err);
                },
            );
        });
    }

    public getLocalAvalaible() {
        return this.localAvalaible;
    }

    public getPatientReady(channelId?: string) {
        if (!channelId) {
            channelId = this.channel;
        }

        if (!channelId) {
            return of(null);
        }
        return this.channels[channelId].patientReady;
    }

    public getChannelInfo(channelId?: string) {
        if (!channelId) {
            channelId = this.channel;
        }

        if (!channelId) {
            return of(null);
        }
        return this.channels[channelId].channelInfo$;
    }

    public getOutsideCallInProgress() {
        return this.outsideCallInProgress$;
    }

    public getCallState() {
        return this.callState$;
    }

    public getCurrentCallChannel() {
        return this.currentCallChannel$;
    }

    public setCallState(state: CallStateEnum) {
        if (this.callState !== state) {
            this.callState = state;
            this.callState$.next(state);
            if (this.callState !== CallStateEnum.CALL_STARTED) {
                this.toggleModalSize('modal');
            }
        }
    }

    public getActiveVideoDevice() {
        return this.activeVideoDevice$;
    }

    public getActiveAudioDevice() {
        return this.activeAudioDevice$;
    }

    public getActivePlayoutDevice() {
        return this.activePlayoutDevice$;
    }

    public getLocalVideoEnabled() {
        return this.localVideoEnabled$;
    }

    public getLocalAudioEnabled() {
        return this.localAudioEnabled$;
    }

    public getAudioVolume() {
        return this.audioVolume$;
    }

    public getWindowSize() {
        return this.windowSize$;
    }

    public getWindowPosition() {
        return this.windowPosition$;
    }

    private initLocalMedia() {
        this.localStream = this.agoraService.createStream({
            streamID: this.uid,
            audio: true,
            video: true,
            screen: false,
        });
        this.localStream.setVideoProfile('720p_3');
        this.assignLocalStreamHandlers();
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private join(): Observable<any> {
        const response = new ReplaySubject(1);

        //console.log(this.client.channel);
        this.client.join(
            this.token,
            this.channel,
            this.uid,
            () => {
                //this.setCallState(CallStateEnum.CHANNEL_JOINED);
                this.sendSocketMessage(this.channel, {
                    clientJoined: true,
                    uid: this.uid,
                });
                response.next(true);
                response.complete();
            },
            error => {
                //This means that we are already in channel.
                this.sendSocketMessage(this.channel, {
                    clientJoined: false,
                    uid: this.uid,
                });
                response.error(error);
                response.complete();
            },
        );
        return response;
    }

    private publish(): void {
        this.sendSocketMessage(this.channel, {
            streamPublished: true,
        });
        this.client.publish(this.localStream, err => {
            console.log('Publish local stream error: ' + err);
            this.sendSocketMessage(this.channel, {
                streamPublished: false,
            });
        });
        // setInterval(() => { this.audioLevel(); }, 100);
    }

    private unPublish(skipNotify: boolean = false): void {
        if (this.client) {
            this.sendSocketMessage(this.channel, {
                streamPublished: false,
            });
            this.client.unpublish(this.localStream, error => {
                console.error(error);
            });
        }
        if (!skipNotify) {
            this.setCallState(CallStateEnum.CALL_ENDING);
        }
    }

    private assignLocalStreamHandlers(): void {
        this.localStream.on(StreamEvent.MediaAccessAllowed, () => {
            this.loadCameras();
            this.loadRecordingDevice();
            this.loadPlayoutDevices();
        });
        // The user has denied access to the camera and mic.
        this.localStream.on(StreamEvent.MediaAccessDenied, () => {
            console.log('accessDenied');
        });

        this.localStream.on('player-status-change' as StreamEvent, evt => {
            if (evt.mediaType === 'video' && evt.streamId === this.uid) {
                this.activeVideoDevice$.next(this.localStream.getVideoTrack() as MediaStreamTrack);
            }

            if (evt.mediaType === 'audio' && evt.streamId === this.uid) {
                this.activeAudioDevice$.next(this.localStream.getAudioTrack() as MediaStreamTrack);
            }
        });
    }

    private assignClientHandlers(): void {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.client.on(ClientEvent.LocalStreamPublished, evt => {
            //this.callState = 3;
        });

        this.client.on(ClientEvent.Error, error => {
            console.log('Got error msg:', error.reason);
            if (error.reason === 'DYNAMIC_KEY_TIMEOUT') {
                this.client.renewChannelKey(
                    '',
                    () => console.log('Renewed the channel key successfully.'),
                    renewError => console.error('Renew channel key failed: ', renewError),
                );
            }
        });

        this.client.on(ClientEvent.RemoteStreamSubscribed, evt => {
            const stream = (this.remoteStream = evt.stream as Stream);
            stream.setAudioVolume(this.audioVolume);
            setTimeout(() => {
                stream.play('agora-remote-video');
                //this.setCallState(CallStateEnum.CALL_STARTED);
            });
        });

        this.client.on(ClientEvent.RemoteStreamAdded, evt => {
            const stream = (this.remoteStream = evt.stream as Stream);
            stream.setAudioVolume(this.audioVolume);
            this.addPeer(stream.getId(), stream);
            if (this.callState === CallStateEnum.CALL_STARTED || this.callState === CallStateEnum.CALL_STARTING) {
                if (this.checkPatient(this.channel)) {
                    const patientClient = this.getPatient(this.channel);
                    if (patientClient.uid === stream.getId()) {
                        this.client.subscribe(evt.stream, { audio: true, video: true }, err => {
                            console.log('Subscribe stream failed', err);
                        });
                    } else {
                        console.error(`UID mismatch on RemoteStreamAdded, ${patientClient.uid}, ${stream.getId()}`);
                    }
                }
            }
        });

        this.client.on(ClientEvent.RemoteStreamRemoved, evt => {
            console.log('remove');
            console.log(evt);
            const stream = (this.remoteStream = evt.stream as Stream);
            if (stream) {
                stream.stop();
                stream.close();
                //this.remoteCalls = [];
                console.log(`Remote stream is removed ${stream.getId()}`);
            }
            this.removePeer(evt.uid);
        });

        this.client.on(ClientEvent.PeerLeave, evt => {
            /*const stream = (this.remoteStream = evt.stream as Stream);
            console.log(stream);
            console.log(`${evt.uid} left from this channel`);*/
            const stream = (this.remoteStream = evt.stream as Stream);
            this.removePeer(evt.uid);
            if (stream) {
                setTimeout(() => {
                    stream.stop();
                });
            }
        });

        this.client.on(ClientEvent.PeerOnline, evt => {
            this.addPeer(evt.uid);
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
        this.client.on(ClientEvent.ConnectionStateChanged, evt => {});
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private removePeer(uid: any) {
        const existingPeer = this.peers.findIndex(peer => {
            return peer.uid === uid;
        });
        if (existingPeer !== -1) {
            this.peers.splice(existingPeer, 1);
        }
        //this.checkPatient(channelId);
    }

    private addPeer(uid: number, stream: boolean | Stream = null) {
        const existingPeer = this.peers.findIndex(peer => {
            return peer.uid === uid;
        });
        if (existingPeer === -1) {
            //this.peers.push(data);
            this.peers.push({ uid: uid, stream: stream });
        } else if (stream !== null) {
            this.peers[existingPeer].stream = stream;
        }
        //this.checkPatient(channelId);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private removePatient(channelId: string, data: any) {
        const existingClient = this.channels[channelId].remoteClients.findIndex(remoteClient => {
            return remoteClient.userId === data.userId;
        });
        if (existingClient !== -1) {
            this.channels[channelId].remoteClients.splice(existingClient, 1);
        }
        this.checkPatient(channelId);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    private addPatient(channelId: string, data: any, stream: boolean | Stream = null) {
        const existingClient = this.channels[channelId].remoteClients.findIndex(remoteClient => {
            return remoteClient.userId === data.userId;
        });
        if (existingClient === -1) {
            this.channels[channelId].remoteClients.push(data); //Tukaj se je vpisal še stream
            //} else if (stream !== null) {
        } else {
            this.channels[channelId].remoteClients[existingClient] = {
                ...this.channels[channelId].remoteClients,
                ...data,
            }; //Tukaj se je vpisal še stream
            //this.remoteClients[existingClient].stream = stream;
        }
        this.checkPatient(channelId);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private removeDoctor(channelId: string, data: any) {
        const existingClient = this.channels[channelId].doctorClients.findIndex(doctorClient => {
            return doctorClient.userId === data.userId || doctorClient.uid === data.uid;
        });
        if (existingClient !== -1) {
            this.channels[channelId].doctorClients.splice(existingClient, 1);
        }
        this.checkPublishedDoctors(channelId);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private addDoctor(channelId: string, data: WebSocketChannel, stream: boolean | Stream = null) {
        const existingClient = this.channels[channelId].doctorClients.findIndex(doctorClient => {
            return doctorClient.userId === data.userId;
        });
        if (existingClient === -1) {
            this.channels[channelId].doctorClients.push(data);
            //this.doctorClients.push({ uid: uid, stream: stream });
        } else {
            this.channels[channelId].doctorClients[existingClient] = {
                ...this.channels[channelId].doctorClients[existingClient],
                ...data,
            };
            //} else if (stream !== null) {
            //this.doctorClients[existingClient].stream = stream;
        }
        this.checkPublishedDoctors(channelId);
    }

    private isPatient(data: WebSocketChannel) {
        return data.userType === 'patient';
        //return ('' + uid).charAt(0) === '2' ? true : false;
    }

    private isDoctor(data: WebSocketChannel) {
        return data.userType === 'doctor';
        //return ('' + uid).charAt(0) === '1' ? true : false;
    }

    private getPatient(channelId: string): WebSocketChannel {
        let patientClient = this.channels[channelId].remoteClients.findIndex(remoteClient => {
            return remoteClient.userType === 'patient' && remoteClient.clientReady;
            //return ('' + remoteClient.uid).charAt(0) === '2';
        });

        if (patientClient === -1) {
            patientClient = this.channels[channelId].remoteClients.findIndex(remoteClient => {
                return remoteClient.userType === 'patient';
                //return ('' + remoteClient.uid).charAt(0) === '2';
            });
        }

        if (patientClient === -1) {
            return null;
        }
        const patient = this.channels[channelId].remoteClients[patientClient];

        const patientPeer = this.peers.find(peer => {
            return peer.uid === patient.uid;
        });

        if (patientPeer) {
            patient.peer = patientPeer;
        }

        return patient;
    }

    private checkPatient(channelId: string) {
        const patientClient = this.getPatient(channelId);
        const ret = {
            status: false,
            userData: null,
        };
        if (patientClient) {
            //ret = patientClient.stream ? true : false;
            if (
                patientClient.onTerm &&
                //patientClient.cameraReady &&
                //patientClient.microphoneReady &&
                patientClient.clientReady
                //patientClient.clientJoined &&
                //patientClient.streamPublished
            ) {
                ret.status = true;
            }
            ret.userData = patientClient;
            this.channels[channelId].patientReady.next(ret);
        } else {
            //ret = false;
            this.channels[channelId].patientReady.next(ret);
        }
        return ret;
    }

    private checkPublishedDoctors(channelId: string) {
        const foundPublishedDoctor = this.channels[channelId].doctorClients.findIndex(doctor => {
            return doctor.inCall === true;
        });
        if (foundPublishedDoctor === -1) {
            this.outsideCallInProgress$.next(null);
            return false;
        } else {
            this.outsideCallInProgress$.next(this.channels[channelId].doctorClients[foundPublishedDoctor]);
            return true;
        }
    }

    public startCall() {
        if (!this.channel) {
            this.mfToast.error('Prišlo je do napake. Manjka ID klica!');
            return;
        }

        if (this.checkPublishedDoctors(this.channel)) {
            this.mfToast.error('Video klic ni mogoč! Klic že poteka z nekom drugim.');
            return;
        }
        if (!this.checkPatient(this.channel)) {
            this.mfToast.error('Video klic ni mogoč! Pacient nima vzpostavljene povezave.');
            return;
        }
        this.join().subscribe(() => {
            this.publish();
            this.sendSocketStartMessage(this.channel);
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const patientClient = this.getPatient(this.channel);
            this.setCallState(CallStateEnum.CALL_STARTED);
            this.sendSocketMessage(this.channel, {
                clientReady: true,
                inCall: true,
            });
            /*this.client.subscribe(patientClient.stream, { audio: true, video: true }, (err) => {
                console.log('Subscribe stream failed', err);
            });
            */
        });
    }

    public closeCallWindow() {
        //this.stopDemoSound();
        this.channel = null;
        this.currentCallChannel$.next(this.channel);
        this.localStream.close();
        this.setCallState(CallStateEnum.NONE);
        this.telemedInitialised = false;
    }

    public restartCall() {
        this.startCall();
    }

    public endCall(): void {
        //this.fs.turnOffFullScreen();
        //this.stopDemoSound();
        this.unPublish();
        //this.remoteStream.stop();
        if (this.client && this.client.getConnectionState() !== 'DISCONNECTED') {
            this.client.leave(
                () => {
                    this.sendSocketMessage(this.channel, {
                        clientJoined: false,
                        inCall: false,
                        clientReady: false,
                    });
                    this.doctorClients = [];
                    this.remoteClients = [];
                    this.setCallState(CallStateEnum.CALL_ENDING);
                },
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                err => {
                    console.log('Leave channel failed');
                },
            );
        } else {
            this.sendSocketMessage(this.channel, {
                clientJoined: false,
                inCall: false,
                clientReady: false,
            });
            this.doctorClients = [];
            this.remoteClients = [];
            this.setCallState(CallStateEnum.CALL_ENDING);
        }
    }

    public leaveCall(): void {
        //this.stopDemoSound();
        this.unPublish(true);
        this.channel = null;
        this.currentCallChannel$.next(null);
        if (this.client && this.client.getConnectionState() !== 'DISCONNECTED') {
            this.client.leave(
                () => {
                    if (this.localStream) {
                        this.localStream.close();
                    }
                    this.patientReady.next(false);
                    this.outsideCallInProgress$.next(null);
                    this.sendSocketMessage(this.channel, {
                        clientJoined: false,
                        inCall: false,
                        clientReady: false,
                    });
                    this.doctorClients = [];
                    this.remoteClients = [];
                    this.setCallState(CallStateEnum.NONE);
                },
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                err => {
                    console.log('Leave channel failed');
                },
            );
        } else {
            if (this.localStream) {
                this.localStream.close();
            }
            this.patientReady.next(false);
            this.outsideCallInProgress$.next(null);
            this.sendSocketMessage(this.channel, {
                clientJoined: false,
                inCall: false,
                clientReady: false,
            });
            this.doctorClients = [];
            this.remoteClients = [];
            this.setCallState(CallStateEnum.NONE);
            this.setCallState(CallStateEnum.NONE);
        }
    }

    public toggleAudio(forceTo?: boolean) {
        if (forceTo === false || forceTo === true) {
            this.localAudioEnabled = !forceTo;
        }
        if (!this.localAudioEnabled) {
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line no-empty
            if (this.localStream.unmuteAudio() as unknown) {
            }
            this.localAudioEnabled = true;
        } else {
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line no-empty
            if (this.localStream.muteAudio() as unknown) {
            }
            this.localAudioEnabled = false;
        }
        this.localAudioEnabled$.next(this.localAudioEnabled);
    }

    public toggleVideo(forceTo?: boolean) {
        if (forceTo === false || forceTo === true) {
            this.localVideoEnabled = !forceTo;
        }
        if (!this.localVideoEnabled) {
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line no-empty
            if (this.localStream.unmuteVideo() as unknown) {
            }
            this.localVideoEnabled = true;
        } else {
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line no-empty
            if (this.localStream.muteVideo() as unknown) {
            }
            this.localVideoEnabled = false;
        }
        this.localVideoEnabled$.next(this.localVideoEnabled);
    }

    public setAudioVolume(setTo: number = null) {
        let setVolumeTo = 100;
        if (setTo !== null) {
            setVolumeTo = setTo;
        } else {
            if (this.audioVolume > 50) {
                setVolumeTo = 50;
            } else if (this.audioVolume > 0) {
                setVolumeTo = 0;
            } else if (this.audioVolume === 0) {
                setVolumeTo = 100;
            }
        }

        this.audioVolume = setVolumeTo;
        if (this.remoteStream) {
            this.remoteStream.setAudioVolume(setVolumeTo);
        }
        if (this.localStream) {
            this.localStream.setVolumeOfEffect(1, setVolumeTo);
        }
        this.audioVolume$.next(setVolumeTo);
    }

    public toggleFullScreen(element) {
        if (this.fs.toggleFullScreen(element)) {
            this.windowSize = 'full';
            this.windowSize$.next('full');
        } else {
            this.windowSize = 'modal';
            this.windowSize$.next('modal');
        }
    }

    public toggleModalSize(forceTo?: string) {
        if (forceTo === 'modal' || (!forceTo && this.windowSize === 'small')) {
            this.windowSize = 'modal';
            this.windowSize$.next('modal');
        } else if (!forceTo || forceTo !== this.windowSize) {
            this.fs.turnOffFullScreen();
            this.windowSize = 'small';
            this.windowSize$.next('small');
            this.windowPosition$.next(this.windowPosition);
        }
    }

    public toggleModalPosition(position: string): void {
        this.windowPosition$.next(position);
        this.windowPosition = position;
    }

    public stopDemoSound() {
        if (this.localStream) {
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            this.localStream.stopAllEffects(() => {});
        }
    }

    public playDemoSound() {
        if (this.localStream) {
            this.localStream.playEffect(
                {
                    filePath: '/assets/app/media/voice-demo-v2.mp3',
                    soundId: 1,
                },
                error => {
                    if (error) {
                        this.localStream.stopEffect(1);
                    }
                },
            );
        }
    }

    private killAll() {
        const newVideoTrack = this.localStream.getVideoTrack();
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.localStream.replaceTrack(<any>newVideoTrack);

        const newAudioTrack = this.localStream.getAudioTrack();
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.localStream.replaceTrack(<any>newAudioTrack);
    }

    public switchCamera(cameraId: string) {
        const newVideoTrack = this.localStream.getVideoTrack();
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.localStream.replaceTrack(<any>newVideoTrack);
        this.localStream.switchDevice('video', cameraId);
    }

    public switchAudio(audioId: string) {
        const newAudioTrack = this.localStream.getAudioTrack();
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.localStream.replaceTrack(<any>newAudioTrack);
        this.localStream.switchDevice('audio', audioId);
    }

    public switchPlayoutDevice(device: MediaDeviceInfo) {
        //const newTrack = this.localStream.getA();
        //his.localStream.replaceTrack(<any>newAudioTrack);
        if (device.deviceId === 'default-none') {
            return;
        }
        this.localStream.setAudioOutput(device.deviceId, () => {
            this.activePlayoutDevice$.next(device);
        });
    }
}
