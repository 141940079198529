import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Comment } from '../../../../_shared/model';
import { HelperService, StorageService } from '../../../../_shared/services';
import { HttpClientService } from '../../../../_shared/services/http-client.service';
import { CriticalData } from './../../../../_shared/model/criticalData.model';

@Injectable()
export class CommentService {
    constructor(
        private http: HttpClient,
        private httpClient: HttpClientService,
        private storage: StorageService,
        private helper: HelperService,
    ) {}

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public addRVComment(userId: number, comment: string, prereservationId: string, visitId?: number): Observable<any> {
        if (visitId) {
            return this.addVisitComment(comment, visitId);
        } else {
            return this.addReservationComment(userId, comment, prereservationId);
        }
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public addReservationComment(userId: number, comment: string, prereservationId: string): Observable<any> {
        const data = {
            userId: userId,
            comment: comment,
            prereservationId: prereservationId,
        };

        return this.httpClient.post(this.helper.getApiUrl() + '/reservations/comments', data, {
            responseType: 'text',
        });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public addVisitComment(comment: string, visitId: number): Observable<any> {
        const data = {
            comment: comment,
        };

        return this.httpClient.post(this.helper.getVisitUrl() + '/visits/' + visitId + '/comments', data, {
            responseType: 'text',
        });
    }

    public getCommentsRV(prereservationId: string, visitId?: number): Observable<Comment[]> {
        if (visitId) {
            return this.getCommentsVisit(visitId);
        } else {
            return this.getCommentsReservation(prereservationId);
        }
    }

    public getCommentsReservation(prereservationId: string): Observable<Comment[]> {
        return this.httpClient.get(this.helper.getApiUrl() + '/reservations/' + prereservationId + '/comments').pipe(
            map(val => {
                return val.comments;
            }),
        );
    }

    public getCommentsVisit(visitId: number): Observable<Comment[]> {
        return this.httpClient.get(this.helper.getVisitUrl() + '/visits/' + visitId + '/comments').pipe(
            map(val => {
                return val.comments;
            }),
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deleteRVComment(prereservationId: string, commentId: number, visitId?: number): Observable<any> {
        if (visitId) {
            return this.deleteVisitComment(visitId, commentId);
        } else {
            return this.deleteReservationComment(prereservationId, commentId);
        }
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deleteReservationComment(prereservationId: string, commentId: number): Observable<any> {
        return this.httpClient.delete(this.helper.getApiUrl() + '/reservations/' + prereservationId + '/comments/' + commentId, {
            responseType: 'text',
        });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deleteVisitComment(visitId: number, commentId: number): Observable<any> {
        return this.httpClient.delete(this.helper.getVisitUrl() + '/visits/' + visitId + '/comments/' + commentId, {
            responseType: 'text',
        });
    }

    //EDIT

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public editRVComment(prereservationId: string, comment: Comment, visitId?: number): Observable<any> {
        if (visitId) {
            return this.editVisitComment(visitId, comment);
        } else {
            return this.editReservationComment(prereservationId, comment);
        }
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public editReservationComment(prereservationId: string, comment: Comment): Observable<any> {
        return this.httpClient.put(this.helper.getApiUrl() + '/reservations/' + prereservationId + '/comments/' + comment.id, comment, {
            responseType: 'text',
        });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public editVisitComment(visitId: number, comment: Comment): Observable<any> {
        return this.httpClient.put(this.helper.getVisitUrl() + '/visits/' + visitId + '/comments/' + comment.id, comment, {
            responseType: 'text',
        });
    }

    // #########CUSTOMER###########
    //getCustomerComments
    public getCustomerComments(customerId: number): Observable<Comment[]> {
        return this.httpClient.get(this.helper.getApiUrl() + '/customers/' + customerId + '/comments').pipe(
            map(val => {
                return val.comments;
            }),
        );
    }

    public getAllCustomerComments(contractorId: number, customerId: number): Observable<Comment[]> {
        return this.httpClient.get(this.helper.getApiUrl(2) + `/contractors/${contractorId}/customers/${customerId}/comments/all`).pipe(
            map(val => {
                return val.comments;
            }),
        );
    }

    //addCustomerComment
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public addCustomerComment(comment: string, customerId: number): any {
        const data = {
            comment: comment,
        };

        return this.httpClient.post(this.helper.getApiUrl() + '/customers/' + customerId + '/comments', data, {
            responseType: 'text',
        });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deleteCustomerComment(customerId: number, commentId: number): Observable<any> {
        return this.httpClient.delete(this.helper.getApiUrl() + '/customers/' + customerId + '/comments/' + commentId, {
            responseType: 'text',
        });
    }

    //editCustomerComment()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public editCustomerComment(customerId: number, comment: Comment): Observable<any> {
        return this.httpClient.put(this.helper.getApiUrl() + '/customers/' + customerId + '/comments/' + comment.id, comment, {
            responseType: 'text',
        });
    }

    //criticalData
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getCustomerCriticalData(contractorId: number, customerId: number): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl(2) + `/contractors/${contractorId}/customers/${customerId}/importantcomments`);
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public addCustomerCriticalData(contractorId: number, customerId: number, criticalData: CriticalData): Observable<any> {
        return this.httpClient.post(
            this.helper.getApiUrl(2) + `/contractors/${contractorId}/customers/${customerId}/importantcomments`,
            criticalData,
        );
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public editCustomerCriticalData(contractorId: number, customerId: number, criticalData: CriticalData, id: any): Observable<any> {
        return this.httpClient.put(
            this.helper.getApiUrl(2) + `/contractors/${contractorId}/customers/${customerId}/importantcomments/${id}`,
            criticalData,
        );
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deleteCustomerCriticalData(contractorId: number, customerId: number, id: any): Observable<any> {
        return this.httpClient.delete(this.helper.getApiUrl(2) + `/contractors/${contractorId}/customers/${customerId}/importantcomments/${id}`);
    }
}
