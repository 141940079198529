import { Serializable } from '../deserialize.service';
import { DocumentSign } from './../portfolio/documentSign.model';

export class OpenDocumentRequest implements Serializable<OpenDocumentRequest> {
    document: Blob;
    signature: DocumentSign;

    deserialize(input) {
        this.document = input.document;
        this.signature = input.signature;
        return this;
    }
}
