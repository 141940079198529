import { Location } from '@angular/common';
import { AfterViewInit, Component, HostListener, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';
import moment from 'moment';
import { forkJoin, Subject, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import * as Tone from 'tone';
import { environment } from '../../../../environments/environment';
import { Contractor, ContractorSettings, GuiUserContractorSettings, MenuItem, User } from '../../../_shared/model';
import { Notification } from '../../../_shared/model/notification.model';
import { MfToastService, RulesService, StorageService } from '../../../_shared/services';
import { ActiveModulesService } from '../../../_shared/services/activeModules.service';
import { HelperMedicalService } from '../../../_shared/services/helper-medical.service';
import { HelperNotificationService } from '../../../_shared/services/helper-notification.service';
import { MenuService } from '../../../_shared/services/menu.service';
import { OneSignalService } from '../../../_shared/services/onesignal.service';
import { QuickSidebarService } from '../../../_shared/services/quickSidebar.service';
import { WebsocketActions } from '../../../_shared/state/websocket/websocket.actions';
import { IncomingCallHDPopupComponent } from '../../components/incomingCall-HD-popup/incomingCall-HD-popup.component';
import { TelephonyService } from '../../pages/home/telephony/telephony.service';
import { CommonService, ReservationService } from '../../pages/home/_services';
import { ConfigGUIService } from '../../pages/home/_services/configGUI.service';
import { CallStateEnum } from '../../pages/telemedicine/call-state.enum';
import { Helpers } from './../../../helpers';
import { AppModulesType } from './../../../_shared/enums/appModulesType.enum';
import { HelloDoctorWebSocketActions } from './../../../_shared/enums/hdWebSocketActions.enum';
import { ReservationType } from './../../../_shared/enums/schedule/reservationType.enum';
import { PreReservation } from './../../../_shared/model/preReservation.model';
import { GuiUserSettings } from './../../../_shared/model/userGuiSettings/guiUserSettings.model';
import { ContractorGroup } from './../../../_shared/model/utils/contractorGroup.model';
import { HelperService } from './../../../_shared/services';
import { ActionsService } from './../../../_shared/services/actions.service';
import { HdWebsocketService } from './../../../_shared/services/hd-websocket.service';
import { HelperNavigateService } from './../../../_shared/services/helper-navigate.service';
import {
    ClearServiceCache,
    SetActiveContractor,
    SetActiveModules,
    SetDefaultUserSettings,
    SetUserSettings,
} from './../../../_shared/state/base/base.actions';
import { WebsocketState } from './../../../_shared/state/websocket/websocket.state';
import { SettingsService } from './../../pages/home/_services/settings.service';
import { TermsService } from './../../pages/home/_services/terms.service';
import { VisitService } from './../../pages/home/_services/visit.service';
import { TelemedicineService } from './../../pages/telemedicine/telemedicine.service';
import { AsnMenuActionsService } from './asn-menu-actions.service';
// TODO Ignored with eslint-interactive on 2023-11-10
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let mLayout: any;
@Component({
    selector: 'app-aside-nav',
    templateUrl: './aside-nav.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./aside-nav.component.scss'],
})
export class AsideNavComponent implements OnInit, AfterViewInit {
    // private user: User;
    public show = true;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public settingsMenuList: any[];
    public mainMenu: MenuItem[];
    public settingsMenu: MenuItem[];
    public isMenuMinimized = false;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    insideTm: any;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    outsideTm: any;

    public loggedingUser: User = this.storage.getUserData();
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public userRoles: any = this.storage.getUserRoles();
    public selectedContractor: Contractor = this.storage.getSelectedContractor();
    public notificationsList: Notification[] = [];
    public isTest: boolean = environment.test;
    public showAlert = false;
    public numberOfNotifications = 0;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public env: any = environment;
    public isInboxModuleActive = false;
    public isHelloDoctor = false;
    public newInboxItems = 0;
    public incomingCallPopup: NgbModalRef;
    public incomingCallCustomerId: string;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public dataFromCall: any;
    public callWaitingQueue = [];

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private toastList: any = {
        1: 123,
    };

    public isActivePhoneCall = false;

    // private interval$: Subscription;
    private onDestroy$: Subject<void> = new Subject<void>();
    constructor(
        private storage: StorageService,
        private menuService: MenuService,
        private commonRest: CommonService,
        private configGUIRest: ConfigGUIService,
        private toast: MfToastService,
        private rules: RulesService,
        private helperNotification: HelperNotificationService,
        private quickSidebarService: QuickSidebarService,
        private render: Renderer2,
        private activeModules: ActiveModulesService,
        private mta: HelperMedicalService,
        private oneSignal: OneSignalService,
        private store: Store,
        private location: Location,
        private helper: HelperService,
        private helperNavigate: HelperNavigateService,
        private hdws: HdWebsocketService,
        private route: Router,
        private modalService: NgbModal,
        private reservationRest: ReservationService,
        private visitRest: VisitService,
        private telemedService: TelemedicineService,
        private termsRest: TermsService,
        private menuActions: AsnMenuActionsService,
        private activatedRoute: ActivatedRoute,
        private actions: ActionsService,
        private settingsRest: SettingsService,
        private telephony: TelephonyService,
    ) {}

    @HostListener('window:unload', ['$event'])
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    unloadHandler(event) {
        this.onDestroy$.next();
    }

    ngOnInit() {
        this.isMenuMinimized = _.get(this.storage.getGuiUserSettings(), 'app.isMenuMinimized', false);

        this.configGUIRest.onToggleMenu.subscribe(res => {
            this.isMenuMinimized = res;
        });

        this.mainMenu = this.menuService.validateMenu(this.menuService.default);
        this.settingsMenu = this.menuService.validateMenu(this.menuService.settingsMenuList);
        this.isInboxModuleActive = this.activeModules.isAM(AppModulesType.INBOX);
        this.isHelloDoctor = this.activeModules.isAM(AppModulesType.HELLO_DOCTOR);

        this.telephony.onOutgoingAnswered.pipe(takeUntil(this.onDestroy$)).subscribe(() => (this.isActivePhoneCall = true));
        this.telephony.onOutgoingCall.pipe(takeUntil(this.onDestroy$)).subscribe(() => (this.isActivePhoneCall = true));
        this.telephony.onCallEnded.pipe(takeUntil(this.onDestroy$)).subscribe(() => (this.isActivePhoneCall = false));

        if (this.isHelloDoctor) {
            this.hdws
                .connect()
                .pipe(
                    filter(
                        () =>
                            this.storage.getUserGuiSettings()?.haloDoctor?.isActive !== false &&
                            this.storage.getUserData()?.subcontractorId != undefined,
                    ),
                    // TODO Ignored with eslint-interactive on 2023-11-10
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    map((rows: any) => {
                        this.setWaitingQueue(rows);
                        if (
                            rows.action == HelloDoctorWebSocketActions.ADD_TO_LIST &&
                            this.incomingCallPopup?.componentInstance == undefined &&
                            this.telemedService.callState == CallStateEnum.NONE &&
                            !this.isActivePhoneCall
                        ) {
                            this.playSound();
                            this.incomingCallCustomerId = rows.customerId;
                            this.openCallPopup(rows);
                        } else if (rows.action == HelloDoctorWebSocketActions.REMOVE_FROM_LIST && rows.customerId === this.incomingCallCustomerId) {
                            //ostranim obstojeci popup ker je bil ze prevzet
                            this.toast.clear(this.toastList[this.incomingCallCustomerId]);
                            delete this.toastList[this.incomingCallCustomerId];
                            this.incomingCallPopup?.close();
                            if (this.callWaitingQueue.length) {
                                this.incomingCallCustomerId = this.callWaitingQueue[0].customerId;
                                this.openCallPopup(this.callWaitingQueue[0]);
                                // TODO Ignored with eslint-interactive on 2023-11-10
                                // eslint-disable-next-line no-empty
                            } else {
                            }
                            // TODO Ignored with eslint-interactive on 2023-11-10
                            // eslint-disable-next-line no-empty
                        } else if (rows.action == HelloDoctorWebSocketActions.REMOVE_FROM_LIST && rows.customerId !== this.incomingCallCustomerId) {
                        } else if (
                            rows.action == HelloDoctorWebSocketActions.ERROR &&
                            rows.subContractor !== this.storage.getUserData().subcontractorId
                        ) {
                            // Drug doktor HKRATI prevzel klic
                            this.toast.info('Drug doktor je že prevzel klic.');
                            this.callWaitingQueue = this.callWaitingQueue?.filter(el => el.customerId != rows.customer);
                            if (this.callWaitingQueue.length) {
                                this.incomingCallCustomerId = this.callWaitingQueue[0].customerId;
                                this.openCallPopup(this.callWaitingQueue[0]);
                            }
                        } else if (
                            rows.action == HelloDoctorWebSocketActions.SUCCESS &&
                            rows.subContractor == this.storage.getUserData().subcontractorId
                        ) {
                            // Uspešno sprejet klic
                            this.takeOver(this.dataFromCall);
                        }
                    }),
                    catchError(error => {
                        throw error;
                    }),
                    tap({
                        error: error => console.log('[Live component] Error:', error),
                        complete: () => console.log('[Live component] Connection Closed'),
                    }),
                    takeUntil(this.onDestroy$),
                )
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
                .subscribe(res => {});

            //start klic
            this.hdws.initCall({
                action: HelloDoctorWebSocketActions.IDENTIFY,
                subContractorId: this.storage.getUserData()?.subcontractorId,
                subContractorName: this.storage.getUserData()?.name,
            });
        }

        this.store
            .dispatch(new WebsocketActions.Notifications.GetAndLoadNumberOfNotifications())
            .pipe(
                mergeMap(() => this.store.select(WebsocketState.getNewNofitications)),
                tap((newNotufucatins: number) => {
                    this.numberOfNotifications = 0;
                    this.showAlert = false;
                    if (Number(newNotufucatins) > 0) {
                        this.showAlert = true;
                        this.numberOfNotifications = newNotufucatins;
                        this.helperNotification.setNotificationFavicon(true);
                    } else {
                        this.helperNotification.setNotificationFavicon(false);
                    }
                }),
                takeUntil(this.onDestroy$),
            )
            .subscribe();

        this.checkForNewNotifications();

        this.quickSidebarService.onChangeSeenNotifications.subscribe(res => {
            switch (res.type) {
                case 'one':
                    this.numberOfNotifications--;
                    if (this.numberOfNotifications < 0) this.numberOfNotifications = 0;
                    break;
                case 'all':
                    this.numberOfNotifications = 0;
                    break;
                default:
                    break;
            }
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.oneSignal.receivedNotifications.subscribe(notification => {
            this.checkForNewNotifications();
        });
    }

    private playSound(): void {
        const synth = new Tone.MonoSynth().toMaster();

        const now = Tone.now();

        //DENIS
        synth.triggerAttackRelease('B4', '8n', now);
        synth.triggerAttackRelease('G4', '4n', now + 0.5);
        synth.triggerAttackRelease('B4', '4n', now + 2.0);
        synth.triggerAttackRelease('G4', '4n', now + 2.25);
        synth.triggerAttackRelease('B4', '1n', now + 2.4);
        synth.triggerAttackRelease('G4', '2n', now + 2.75);
    }
    ngAfterViewInit() {
        mLayout.initAside();
    }

    public openCallPopup(rows) {
        this.dataFromCall = rows;

        if (this.location.path().includes('calendar/selected') || this.location.path().includes('calendar/visit')) {
            //NE MODALNI POPUP
            const title = this.mta.appName(rows?.reservationType);
            const text = `Kliče stranka: ${rows?.customerName} `;
            const toast = this.toast.hd(text, title);
            this.toastList[rows.customerId] = toast.toastId;

            const onAnwserPopup: Subject<void> = new Subject<void>();

            toast.onTap.pipe(tap(() => onAnwserPopup.next())).subscribe(() => {
                this.onAnwser(rows);
            });

            toast.onHidden.pipe(takeUntil(onAnwserPopup)).subscribe(() => {
                this.onDecline(rows);
            });
        } else {
            //MODALNI POPUP
            this.incomingCallPopup = this.modalService.open(IncomingCallHDPopupComponent, {
                size: 'lg',
                keyboard: false,
                backdrop: 'static',
                windowClass: 'mf-transparent-popup',
            });
            this.incomingCallPopup.componentInstance.customer = rows?.customerName;
            this.incomingCallPopup.componentInstance.reservationType = rows?.reservationType;
            this.incomingCallPopup.result.then(
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
                close => {},
                result => {
                    if (result.action == 'ANSWER') {
                        this.onAnwser(rows);
                    } else {
                        this.onDecline(rows);
                    }
                },
            );
        }
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private onAnwser(rows: any): void {
        this.hdws.send({
            action: HelloDoctorWebSocketActions.LOCK,
            customerId: rows.customerId,
        });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private onDecline(rows: any): void {
        this.callWaitingQueue = this.callWaitingQueue?.filter(el => el.customerId != rows.customerId);
        //zavrni klic --> če je več kot 1 stranka v čakalni vrsti, odpri nov popup
        if (this.callWaitingQueue.length) {
            this.incomingCallCustomerId = this.callWaitingQueue[0].customerId;
            this.openCallPopup(this.callWaitingQueue[0]);
        }
    }

    public setWaitingQueue(data) {
        if (data?.action == HelloDoctorWebSocketActions.ADD_TO_LIST) {
            this.callWaitingQueue = this.callWaitingQueue?.filter(el => el.customerId != data.customerId);
            this.callWaitingQueue.push(data);
        } else if (data?.action == HelloDoctorWebSocketActions.REMOVE_FROM_LIST) {
            this.callWaitingQueue = this.callWaitingQueue?.filter(el => el.customerId != data.customerId);
        }
    }

    public closeIncomingCallPopup(check: boolean = false) {
        if (!this.incomingCallPopup) {
            return;
        }
        if (check) {
            if (this.incomingCallPopup?.componentInstance) {
                this.incomingCallPopup?.close();
            }
        } else {
            this.incomingCallPopup?.close();
        }
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    public openNotificatonSidebar(): void {
        this.quickSidebarService.toggle();
    }
    public checkForNewNotifications(): void {
        this.store.dispatch(new WebsocketActions.Notifications.GetAndLoadNumberOfNotifications()).pipe(takeUntil(this.onDestroy$)).subscribe();
    }

    public showMenu(): void {
        this.show = !this.show;
    }

    public setContractor(contr: Contractor): void {
        this.actions.onStartContractorChange.next(true);

        this.selectedContractor = contr;
        this.store.dispatch(new ClearServiceCache()).pipe(takeUntil(this.onDestroy$));
        this.store.dispatch(new SetActiveContractor(contr)).pipe(takeUntil(this.onDestroy$));
        if (!_.isNil(contr)) {
            this.configGUIRest.updateUserGuiSettings(
                new GuiUserSettings().deserialize({
                    ...this.storage.getUserGuiSettings(),
                    lastSelectedContractorId: contr.id,
                }),
            );
            this.storage.setSelectedContractor(contr);
        }

        forkJoin([
            this.commonRest.getUserRoles(this.selectedContractor.id).pipe(
                tap((roles: string[]) => {
                    if (roles.length < 1) {
                        throw new Error('Nimate pravice za prijavo!');
                    }
                    this.rules.setRules();
                }),
            ),
            this.configGUIRest.getUserContractorGuiSettings(this.selectedContractor.id).pipe(
                tap(userSettings => {
                    if (_.get(userSettings, 'calendar.filter')) {
                        const settings = new GuiUserContractorSettings().deserialize(userSettings);
                        this.storage.setGuiUserContractorSettings(settings);
                        this.store.dispatch(new SetUserSettings(settings)).pipe(takeUntil(this.onDestroy$));
                    } else {
                        this.store.dispatch(new SetDefaultUserSettings(userSettings)).pipe(takeUntil(this.onDestroy$));
                    }
                }),
            ),
            this.configGUIRest.getContractorGuiSettings(this.selectedContractor.id).pipe(
                tap(contractorSettings => {
                    if (contractorSettings) {
                        this.storage.setContractorSettings(new ContractorSettings().deserialize(contractorSettings));
                        this.activeModules.setActiveModules();
                    }
                }),
            ),
            this.commonRest.getUserData().pipe(
                tap(userData => {
                    const contractor: ContractorGroup = userData.userContractors.find(contractor => {
                        return contractor.contractorId === contr.id;
                    });
                    this.storage.setUserData(
                        new User().deserialize(
                            _.assign(userData, { employeeId: contractor.employeeId }, { subcontractorId: contractor.subcontractorId }),
                        ),
                    );
                }),
            ),
            this.settingsRest.getContractorModules(this.selectedContractor.id).pipe(
                tap(modules => {
                    this.store.dispatch([new SetActiveModules(modules)]);
                }),
            ),
        ]).subscribe(
            () => {
                this.toast.info('Menjava izvajalca ...');
                this.redirectTo();
            },
            error => {
                this.toast.error(error.message);
            },
        );
    }
    private redirectTo(): void {
        const url = this.location.path();
        if (/calendar\/selected/.test(url)) {
            // this.router.navigate(['/calendar/main']);
            this.helper.redirectToAndRefresh('/calendar/main');
        } else if (/customers\/view/.test(url)) {
            // this.router.navigate(['customers']);
            this.helper.redirectToAndRefresh('customers');
        } else {
            // window.location.reload(true);
            this.ngOnInit(); // glavni meni
            const currentUrl = this.route.url;
            // const test = this.activatedRoute.snapshot.component;
            this.actions.onEndContractorChange.next(true);
            this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => this.route.navigate([currentUrl]));
        }
    }
    public toggleMenu(): void {
        // tukaj je namenom ubrnjeno, zato da se na gui vidi spremeba nato se shrani v db...
        if (_.get(this.storage.getGuiUserSettings(), 'app.isMenuMinimized', false)) {
            // show the left aside menu
            this.render.removeClass(document.body, 'm-brand--minimize');
            this.render.removeClass(document.body, 'm-aside-left--minimize');
        } else {
            this.render.addClass(document.body, 'm-brand--minimize');
            this.render.addClass(document.body, 'm-aside-left--minimize');
        }

        this.configGUIRest.updateUserGuiAppSettingsToggleMenu(this.storage.getSelectedContractor().id);
    }
    public takeOver(inputData) {
        if (
            !this.storage.getContractorSettings()?.haloDoctor?.selectedAsset?.id ||
            !this.storage.getContractorSettings()?.haloDoctor?.selectedService?.id
        ) {
            this.toast.warning('Definirajte prostor in storitev za HD v nastavitvah ambulante');
            return;
        }
        const data = {
            contractor: {
                id: Number(this.storage.getSelectedContractor().id),
                subcontractors: [
                    {
                        id: this.storage.getUserData().subcontractorId,
                    },
                ],
            },
            from: moment(inputData.time),
            //DEFAULT trajanje storitve 15min
            //15min je na testu, na produkciji pa 30min. Sedaj berem podatek direkt iz storitve
            to: moment(inputData.time).add(this.storage.getContractorSettings()?.haloDoctor?.selectedService?.length, 'minutes'),
            visitTime: moment(inputData.time).toDate(),
            service: {
                id: this.storage.getContractorSettings()?.haloDoctor?.selectedService?.id,
                name: this.storage.getContractorSettings()?.haloDoctor?.selectedService?.name,
            },
            services: [
                {
                    id: this.storage.getContractorSettings()?.haloDoctor?.selectedService?.id,
                    name: this.storage.getContractorSettings()?.haloDoctor?.selectedService?.name,
                },
            ],
            asset: {
                name: this.storage.getContractorSettings()?.haloDoctor?.selectedAsset?.name,
                id: this.storage.getContractorSettings()?.haloDoctor?.selectedAsset?.id,
            },
            // customer: {
            //     id: inputData?.customerId,
            // },
            maskedCustomerId: inputData?.customerId,
            forceUpdate: true,
            telemed: true,
            reservationType: inputData.reservationType ? inputData.reservationType : ReservationType.HDD,
        };
        Helpers.setLoading(true);
        return (
            this.reservationRest
                .sendPreResevation(data)
                .pipe(
                    mergeMap(res =>
                        this.reservationRest.sendResevation(res).pipe(
                            mergeMap(() => {
                                return this.visitRest.createVisitFromPrereservation(res.prereservationId).pipe(
                                    tap(visitId => {
                                        Helpers.setLoading(false);
                                        this.incomingCallCustomerId = undefined;
                                        this.hdws.send({
                                            customerId: inputData?.customerId,
                                            action: HelloDoctorWebSocketActions.CALL,
                                            preReservationId: res.prereservationId,
                                        });
                                        if (this.route.url.includes('selected') || res?.prereservationId) {
                                            this.route.navigate([`calendar/selected/hd/${res.prereservationId}`]).then(() => {
                                                window.location.reload();
                                            });
                                        } else {
                                            this.helperNavigate.navigateToVisit(visitId);
                                        }
                                    }),
                                    catchError(() => {
                                        Helpers.setLoading(false);
                                        this.toast.error('Napaka pri kreiranju obravnave');
                                        this.closeIncomingCallPopup(true);
                                        return this.termsRest.cancelReservation(new PreReservation().deserialize(res));
                                    }),
                                );
                            }),
                        ),
                    ),
                    catchError(error => {
                        Helpers.setLoading(false);
                        this.toast.error('Napaka pri kreiranju rezervacije.');
                        return throwError(error);
                    }),
                )
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                .subscribe(() => {})
        );
    }

    public menuAction(action: string): void {
        this.menuActions.manageActions(action);
    }
}
