import { Serializable } from '../deserialize.service';

export class DocumentSign implements Serializable<DocumentSign> {
    public signer: string;
    public date: string;
    public contractor: string;
    public appendTo = false;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        this.signer = input.signer;
        this.date = input.date;
        this.contractor = input.contractor;
        this.appendTo = input.appendTo;
        return this;
    }
}
