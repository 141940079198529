import { Serializable } from './deserialize.service';

export class Comment implements Serializable<Comment> {
    id: number;
    commentAdded: Date;
    comment: string;
    comentatorName: string;
    fromAmb: boolean;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fromApp: any;
    prereservationId: string;

    deserialize(input) {
        this.id = input.id;
        this.commentAdded = input.commentAdded;
        this.comment = input.comment;
        this.comentatorName = input.comentatorName;
        this.fromAmb = input.fromAmb;
        this.fromApp = input.fromApp;
        this.prereservationId = input.prereservationId;
        return this;
    }
}
