import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// bootstrap
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as FileSaver from 'file-saver';
import * as _ from 'lodash';
// other
import * as moment from 'moment';
import { Moment } from 'moment';
import { NgxPermissionsService } from 'ngx-permissions';
// rxjs
import { BehaviorSubject, from, Observable } from 'rxjs';
import Swal from 'sweetalert2';
// environment
import { environment } from '../../../environments/environment';
// components
import { MediaViewPopupComponent } from '../../theme/components/media-view-popup/media-view-popup.component';
import { tags } from '../constants/customTags.const';
import { App } from '../model/app.model';
import { Business } from '../model/business.model';
import { CustomTagPipe } from '../pipes/custom-tag.pipe';
import { PaymentType } from './../enums/accounting/paymentType.enum';
import { ErrorResponse } from './../model/errorResponse.model';
import { DocumentSign } from './../model/portfolio/documentSign.model';
// import { SendPopupComponent } from './../../theme/components/send-popup/send-popup.component';
import { CalendarFilterSettings } from './../model/userGuiSettings/calendarFilterSettings.model';
import { CalendarUserGuiSettings } from './../model/userGuiSettings/calendarUserGuiSettings.model';
import { AssetGroup } from './../model/utils/assetGroup.model';
import { FilterElement } from './../model/utils/filterElement.model';
import { OpenDocumentRequest } from './../model/utils/openDocumentRequest.model';
// services
import { StorageService } from './storage.service';

@Injectable()
export class HelperService {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public hiddeHeader: BehaviorSubject<any> = new BehaviorSubject(false);
    // public menuList: BehaviorSubject<any> = new BehaviorSubject({});
    public disabledNavigation = false;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public customTags: any = _.clone(tags.CUSTOM_TERMS_TAGS);
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public ROLE_TRANSLATE: any = {
        ROLE_OWNER: 'Vodja',
        ROLE_SUBCONTRACTOR: 'Podizvajalec',
        ROLE_SEKRETAR: 'Sekretar',
        ROLE_WORKINGHOURS_MANAGER: 'Planer',
        ROLE_FRANCHISE_MANAGER: 'Franšizodajalec',
        ROLE_RESTRICTED_SUBCONTRACTOR: 'Podizvajalec2',
        ROLE_EA_TELEPHONY: 'Telefonist',
        ROLE_SUBCONTRACTOR_LIMITED: 'Podizvajalec3',
        ROLE_OIM_PRODUCTION: 'OIM-proizvodnja',
        ROLE_OIM_MODELER: 'OIM-modeler',
        ROLE_ACCOUNTANT: 'Računovodja',
    };

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public ngxDataTableTransletedMessages: any = {
        emptyMessage: 'Ni podatkov',
        totalMessage: 'skupaj',
    };

    // public CUSTOM_TERMS_TAGS: any = [
    //     { name: 'Brez oznake', id: 0, classColor: 'mf-custom-tag' },
    //     { name: 'Rdeča', id: 1, classColor: 'mf-custom-tag__red' },
    //     { name: 'Rumena', id: 2, classColor: 'mf-custom-tag__yellow' },
    //     { name: 'Zelena', id: 3, classColor: 'mf-custom-tag__green' }
    // ];

    constructor(
        private storage: StorageService,
        private permissionsService: NgxPermissionsService,
        private modalService: NgbModal,
        private customTagPipe: CustomTagPipe,
        private route: Router,
    ) {}

    getTriageUrl(version: number = 1): string {
        //http://10.50.40.210:9090/booking/triageapi/contractors
        return (
            environment.triage['v' + version].protocol +
            environment.triage['v' + version].rootUrl +
            environment.triage['v' + version].port +
            environment.triage['v' + version].url
        );
    }

    getApiUrl(version: number = 1): string {
        //http://10.50.40.210:9090/bookin/erp/api
        return (
            environment.service['v' + version].protocol +
            environment.service['v' + version].rootUrl +
            environment.service['v' + version].apiPort +
            environment.service['v' + version].apiUrl
        );
    }

    getTreatmentUrl(version: number = 1): string {
        //http://10.50.40.210:9090/erp/treatments
        return (
            environment.treatment['v' + version].protocol +
            environment.treatment['v' + version].rootUrl +
            environment.treatment['v' + version].port +
            environment.treatment['v' + version].url
        );
    }

    getTelemedUrl(): string {
        //http://10.50.40.210:9090/erp/telemed
        return environment.telemed.protocol + environment.telemed.rootUrl + environment.telemed.port + environment.telemed.url;
    }

    getAuthUrl(): string {
        //'http://10.50.40.210:8080/auth/oauth/token
        return environment.auth.protocol + environment.auth.rootUrl + environment.auth.port + environment.auth.url;
    }

    getRootUrl(version: number = 1, path: string = ''): string {
        if (!path) {
            path = '';
        }
        return (
            environment.root['v' + version].protocol +
            environment.root['v' + version].rootUrl +
            environment.root['v' + version].port +
            environment.root['v' + version].url +
            '/' +
            path
        );

        // return environment.root.protocol + environment.root.rootUrl + environment.root.port + environment.root.url;
    }

    getAirCallUrl(): string {
        return environment.aircall.protocol + environment.aircall.rootUrl + environment.aircall.port + environment.aircall.url;
    }

    getReportUrl(): string {
        //http://10.50.40.210:9090/dentist/api
        return environment.report.protocol + environment.report.rootUrl + environment.report.port + environment.report.url;
    }

    getDocumentUrl(): string {
        //http://localhost:9090/booking/docapi
        return environment.document.protocol + environment.document.rootUrl + environment.document.port + environment.document.url;
    }

    getPrintServerUrl(): string {
        //http://localhost:9090/booking/papi
        return environment.printserver.protocol + environment.printserver.rootUrl + environment.printserver.port + environment.printserver.url;
    }

    getTaskUrl(): string {
        //http://localhost:9090/booking/papi
        return environment.task.protocol + environment.task.rootUrl + environment.task.port + environment.task.url;
    }

    getAccountingUrl(version: number = 1): string {
        //http://localhost:9090/booking/aapi
        return (
            environment.accounting['v' + version].protocol +
            environment.accounting['v' + version].rootUrl +
            environment.accounting['v' + version].port +
            environment.accounting['v' + version].url
        );
    }

    getVisitUrl(version: number = 1): string {
        //http://localhost:9090/booking/visitapi
        return (
            environment.visit['v' + version].protocol +
            environment.visit['v' + version].rootUrl +
            environment.visit['v' + version].port +
            environment.visit['v' + version].url
        );
    }

    getCommunicationUrl(): string {
        //http://localhost:9090/booking/communicationapi
        return (
            environment.communication.protocol + environment.communication.rootUrl + environment.communication.port + environment.communication.url
        );
    }

    getStatsUrl(): string {
        //http://localhost:9090/booking/communicationapi
        return environment.statistics.protocol + environment.statistics.rootUrl + environment.statistics.port + environment.statistics.url;
    }

    getIteroUrl(): string {
        //http://localhost:9090/booking/erp/itero/
        return environment.itero.protocol + environment.itero.rootUrl + environment.itero.port + environment.itero.url;
    }

    getSurveysUrl(): string {
        //http://localhost:9090/booking/surveys
        return environment.surveys.protocol + environment.surveys.rootUrl + environment.surveys.port + environment.surveys.url;
    }

    getAccessUrl(): string {
        //http://localhost:9090/booking/access
        return environment.access.protocol + environment.access.rootUrl + environment.access.port + environment.access.url;
    }

    getAssistanceUrl(version: number = 2): string {
        //http://10.50.40.210:9090/booking/v2/assistance
        return (
            environment.assistance['v' + version].protocol +
            environment.assistance['v' + version].rootUrl +
            environment.assistance['v' + version].port +
            environment.assistance['v' + version].url
        );
    }

    getLocalPrinterUrl(): string {
        //http://10.50.40.210:9090/dentist/api
        return `http://127.0.0.1:${environment.termalPrinter.port}/webdriver/Printer1.bxl`;
    }

    getWebSocketUrl(): string {
        return environment.webSocket.protocol + environment.webSocket.rootUrl + environment.webSocket.port + environment.webSocket.url;
    }

    getWebSocketTelemedUrl(): string {
        return (
            environment.webSocketTelemed.protocol +
            environment.webSocketTelemed.rootUrl +
            environment.webSocketTelemed.port +
            environment.webSocketTelemed.url
        );
    }

    getToolsUrl(): string {
        return environment.toolsUrl.protocol + environment.toolsUrl.rootUrl + environment.toolsUrl.port + environment.toolsUrl.url;
    }

    getErpRootUrl(path?: string): string {
        if (!path) {
            path = '';
        }
        return (
            environment.erpRootUrl.protocol + environment.erpRootUrl.rootUrl + environment.erpRootUrl.port + environment.erpRootUrl.url + '/' + path
        );
    }

    getEav2RootUrl(path?: string): string {
        if (!path) {
            path = '';
        }
        return (
            environment.eav2RootUrl.protocol +
            environment.eav2RootUrl.rootUrl +
            environment.eav2RootUrl.port +
            environment.eav2RootUrl.url +
            '/' +
            path
        );
    }

    getMedicalUrl(path?: string): string {
        if (!path) {
            path = '';
        }
        return environment.medical.protocol + environment.medical.rootUrl + environment.medical.port + environment.medical.url + '/' + path;
    }

    getCustomerDataUrl(): string {
        return (
            environment.erpRootUrl.protocol +
            environment.erpRootUrl.rootUrl +
            environment.erpRootUrl.port +
            environment.erpRootUrl.url +
            '/customerdata'
        );
    }

    getWidgetUrl(): string {
        return environment.widget.protocol + environment.widget.rootUrl + environment.widget.port + environment.widget.url;
    }

    getWSHelloDocUrl(): string {
        return (
            environment.webSocketHelloDoc.protocol +
            environment.webSocketHelloDoc.rootUrl +
            environment.webSocketHelloDoc.port +
            environment.webSocketHelloDoc.url
        );
    }

    getPromApiUrl(path: string = ''): string {
        return this.getErpRootUrl(`promapi/${path}`);
        // return (
        //     environment.promApi.protocol +
        //     environment.promApi.rootUrl +
        //     environment.promApi.port +
        //     environment.promApi.url
        // );
    }

    getContactApiUrl(path: string = ''): string {
        return this.getErpRootUrl(`contactapi${path}`);
    }

    getESZBOApiUrl(path: string = ''): string {
        return this.getEav2RootUrl(`eszbo/api${path}`);
    }

    getExaminationApiUrl(path: string = ''): string {
        return this.getEav2RootUrl(`mdps/api${path}`);
    }

    getLaboratoryApiUrl(path: string = ''): string {
        return this.getEav2RootUrl(`mdps/api${path}`);
    }

    getWizardApi(): string {
        return environment.wizard.protocol + environment.wizard.rootUrl + environment.wizard.port + environment.wizard.url;
    }
    getOimApi(): string {
        return environment.oim.protocol + environment.oim.rootUrl + environment.oim.port + environment.oim.url;
    }

    // http://127.0.0.1:8080/webdriver/Printer1.bxl

    uuid(length: number = 8): string {
        let i, random;
        let result = '';
        // for (i = 0; i < 32; i++)
        for (i = 0; i < length; i++) {
            random = (Math.random() * 16) | 0;
            if (i === 8 || i === 12 || i === 16 || i === 20) {
                result += '-';
            }
            result += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(16);
        }

        return result;
    }

    // public showMessage(text: string): void {
    //     alert('message helper');
    //     // this.snackBar.open(text, 'Obvestilo', {
    //     //     duration: 8000,
    //     //     verticalPosition: 'top'
    //     // });
    // }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public momentToNgbDate(mdate: Moment): any {
        return {
            day: mdate.date(),
            month: mdate.month() + 1,
            year: mdate.year(),
        };
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public displayAlert(title: string, content: string, settings?: any): Promise<any> {
        return Swal.fire(
            Object.assign(
                {
                    icon: 'warning',
                    title: title,
                    text: content,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Potrdi',
                    cancelButtonText: 'Prekliči',
                    reverseButtons: true,
                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-accent m-btn m-btn--custom m-btn--pill',
                    confirmButtonColor: null,
                    cancelButtonClass: 'btn btn-danger m-btn m-btn--custom m-btn--pill',
                    cancelButtonColor: null,
                },
                settings,
            ),
        );
    }

    public precisionRound(number, precision) {
        const factor = Math.pow(10, precision);
        return Math.round(number * factor) / factor;
    }

    public getDateRangeSettingsSubconstractorCalendar(
        startDate: moment.Moment = moment().subtract(1, 'week'),
        endDate: moment.Moment = moment(),
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): any {
        return {
            autoApply: true,
            locale: {
                format: 'DD.MM.YYYY',
                cancelLabel: 'Prekliči',
                applyLabel: 'Potrdi',
                customRangeLabel: 'Izberi ročno',
                daysOfWeek: ['Ne', 'Po', 'To', 'Sr', 'Če', 'Pe', 'So'],
                monthNames: [
                    'Januar',
                    'Februar',
                    'Marec',
                    'April',
                    'Maj',
                    'Junij',
                    'Julij',
                    'Avgust',
                    'September',
                    'Oktober',
                    'November',
                    'December',
                ],
                firstDay: 1,
            },
            alwaysShowCalendars: false,
            ranges: {
                Danes: [moment(), moment()],
                Jutri: [moment().add(1, 'day'), moment().add(1, 'day')],
                'Trenutni teden': [moment().startOf('week'), moment().endOf('week')],
            },
            startDate: startDate,
            endDate: endDate,
        };
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getDateRangeSettngs(startDate: moment.Moment = moment().subtract(1, 'week'), endDate: moment.Moment = moment(), settings?: any): any {
        return Object.assign(
            {
                autoApply: true,
                locale: this.dateSelectorLocale,
                alwaysShowCalendars: false,
                ranges: {
                    Danes: [moment(), moment()],
                    'Zadnjih 7 dni': [moment().subtract(1, 'week'), moment()],
                    'Zadnjih 30 dni': [moment().subtract(1, 'month'), moment()],
                    'Tekoči mesec': [moment().startOf('month'), moment().endOf('month')],
                    'Prejšnji mesec': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                },
                startDate: startDate,
                endDate: endDate,
            },
            settings,
        );
    }

    // public getDateRangeSettngs(
    //     startDate: moment.Moment = moment().subtract(1, 'week'),
    //     endDate: moment.Moment = moment(),
    // ): any {
    //     return {
    //         autoApply: true,
    //         locale: {
    //             format: 'DD.MM.YYYY',
    //             cancelLabel: 'Prekliči',
    //             applyLabel: 'Potrdi',
    //             customRangeLabel: 'Izberi ročno',
    //             daysOfWeek: ['Ne', 'Po', 'To', 'Sr', 'Če', 'Pe', 'So'],
    //             monthNames: [
    //                 'Januar',
    //                 'Februar',
    //                 'Marec',
    //                 'April',
    //                 'Maj',
    //                 'Junij',
    //                 'Julij',
    //                 'Avgust',
    //                 'September',
    //                 'Oktober',
    //                 'November',
    //                 'December',
    //             ],
    //             firstDay: 1,
    //         },
    //         alwaysShowCalendars: false,
    //         ranges: {
    //             Danes: [moment(), moment()],
    //             'Zadnjih 7 dni': [moment().subtract(1, 'week'), moment()],
    //             'Zadnjih 30 dni': [moment().subtract(1, 'month'), moment()],
    //             'Tekoči mesec': [moment().startOf('month'), moment().endOf('month')],
    //         },
    //         startDate: startDate,
    //         endDate: endDate,
    //     };
    // }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getDateRangeFutureSettngs(startDate: moment.Moment = moment(), endDate: moment.Moment = moment().add(1, 'day'), settings?: any): any {
        return Object.assign(
            {
                autoUpdateInput: false,
                autoApply: true,
                locale: this.dateSelectorLocale,
                alwaysShowCalendars: false,
                ranges: {
                    Vsi: [moment(), moment().add(1, 'day')],
                    Danes: [moment(), moment()],
                    'Zadnjih 7 dni': [moment().subtract(1, 'week'), moment()],
                    'Naslednjih 7 dni': [moment(), moment().add(1, 'week')],
                    'Tekoči mesec': [moment().subtract(1, 'month'), moment()],
                    'Naslednji mesec': [moment(), moment().add(1, 'month')],
                    'Prejšnji mesec': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                },
                startDate: startDate,
                endDate: endDate,
            },
            settings,
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getDateSettngs(startDate: moment.Moment = moment()): any {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const result: any = {
            locale: this.dateSelectorLocale,
            singleDatePicker: true,
            autoApply: true,
            alwaysShowCalendars: false,
        };
        if (!_.isNull(startDate)) {
            _.set(result, 'startDate', startDate);
        }
        return result;
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getDateSettingsEmpty(startDate?: moment.Moment): any {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const result: any = {
            locale: this.dateSelectorLocale,
            singleDatePicker: true,
            autoApply: true,
            alwaysShowCalendars: false,
            autoUpdateInput: false,
        };
        if (startDate) {
            _.set(result, 'startDate', startDate);
        }
        return result;
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getDateSettngsEmpty(startDate: moment.Moment = moment()): any {
        return {
            locale: this.dateSelectorLocale,
            singleDatePicker: true,
            autoApply: false,
            autoUpdateInput: false,
            alwaysShowCalendars: false,
            startDate: startDate,
        };
    }

    public getInvoicePopupCalendarOptions() {
        return {
            minDate: moment(),
            autoUpdateInput: false,
            singleDatePicker: true,
            autoApply: true,
            locale: {
                format: 'DD.MM.YYYY',
                cancelLabel: 'Prekliči',
                applyLabel: 'Potrdi',
                customRangeLabel: 'Izberi ročno',
                daysOfWeek: ['Ne', 'Po', 'To', 'Sr', 'Če', 'Pe', 'So'],
                monthNames: [
                    'Januar',
                    'Februar',
                    'Marec',
                    'April',
                    'Maj',
                    'Junij',
                    'Julij',
                    'Avgust',
                    'September',
                    'Oktober',
                    'November',
                    'December',
                ],
                firstDay: 1,
            },
            alwaysShowCalendars: false,
        };
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/no-explicit-any
    private dateSelectorLocale: any = {
        format: 'DD.MM.YYYY',
        cancelLabel: 'Prekliči',
        applyLabel: 'Potrdi',
        customRangeLabel: 'Izberi ročno',
        daysOfWeek: ['Ne', 'Po', 'To', 'Sr', 'Če', 'Pe', 'So'],
        monthNames: ['Januar', 'Februar', 'Marec', 'April', 'Maj', 'Junij', 'Julij', 'Avgust', 'September', 'Oktober', 'November', 'December'],
        firstDay: 1,
    };

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public parseJWT(token: any): any {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public convertBase64StringToBlob(dataURI: string): any {
        dataURI = dataURI?.split(',')[1];
        const byteString = window?.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: 'image/png' });
        return blob;
    }

    public getAppVersion(): string {
        return environment.VERSION;
    }

    public isJson(str): boolean {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    public isDifferentAppVersion(): boolean {
        if (!this.storage.getAppData()) {
            return false;
        }

        if (environment.VERSION === this.storage.getAppData().version) {
            return false;
        }
        return true;
    }

    public timeFrom24(minutes: number): string {
        return moment().startOf('day').add(minutes, 'minutes').format('HH:mm');
    }

    public minFrom24(time: number): number {
        return moment(time, 'HH:mm').diff(moment().startOf('day'), 'minutes');
    }

    public numberToWeekDayName(dayNumber: number): string {
        switch (dayNumber) {
            case 1:
                return 'MONDAY';
            case 2:
                return 'TUESDAY';
            case 3:
                return 'WEDNESDAY';
            case 4:
                return 'THURSDAY';
            case 5:
                return 'FRIDAY';
            case 6:
                return 'SATURDAY';
            case 7:
                return 'SUNDAY';
        }
    }

    public weekDayNameToNumber(dayName: string): number {
        switch (dayName) {
            case 'MONDAY':
                return 1;
            case 'TUESDAY':
                return 2;
            case 'WEDNESDAY':
                return 3;
            case 'THURSDAY':
                return 4;
            case 'FRIDAY':
                return 5;
            case 'SATURDAY':
                return 6;
            case 'SUNDAY':
                return 7;
        }
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getPaymentGroups(): any[] {
        return [
            { name: PaymentType.CASH, active: false, description: 'Gotovina', group: 'Ostalo' },
            { name: PaymentType.ONLINE_PAYMENT, active: false, description: 'Spletno plačilo', group: 'Ostalo' },
            { name: PaymentType.DEBIT_CARD, active: false, description: 'Kartica', group: 'Kartice' },
            { name: PaymentType.CREDIT_CARD, active: false, description: 'Ostale kartice', group: 'Kartice' },
            { name: PaymentType.VOUCHER, active: false, description: 'Kupon', group: 'Kuponi' },
            { name: PaymentType.TRR, active: false, description: 'Bančni račun', group: 'Ostalo' },
            { name: PaymentType.SELECT_BOX, active: false, description: 'Select box bon', group: 'Kuponi' },
            { name: PaymentType.ZVEZDAR, active: false, description: 'Zvezdar bon', group: 'Kuponi' },
            { name: PaymentType.MAESTRO, active: false, description: 'Maestro', group: 'Kartice' },
            { name: PaymentType.MASTERCARD, active: false, description: 'Mastercard', group: 'Kartice' },
            { name: PaymentType.VISA, active: false, description: 'Visa', group: 'Kartice' },
            { name: PaymentType.DINERS, active: false, description: 'Diners', group: 'Kartice' },
            { name: PaymentType.HISA_DARIL, active: false, description: 'Hiša daril', group: 'Kuponi' },
            { name: PaymentType.BON21, active: false, description: 'Bon 21', group: 'Ostalo' },
            { name: PaymentType.ZZZS, active: false, description: 'ZZZS', group: 'Ostalo' },
        ];
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getAssetGroups(): any[] {
        return [
            {
                type: 'DEVICE',
                label: 'Naprava',
            },
            {
                type: 'ROOM',
                label: 'Prostor',
            },
        ];
    }
    public createAssetGroups(data): AssetGroup[] {
        const result = [];
        const tmp = _.groupBy(data, 'assetType');
        for (const group in tmp) {
            switch (group) {
                case 'ROOM':
                    result.push(new AssetGroup().deserialize({ group: group, label: 'Prostor', data: tmp[group] }));
                    break;
                case 'DEVICE':
                    result.push(new AssetGroup().deserialize({ group: group, label: 'Naprava', data: tmp[group] }));
                    break;
            }
        }
        return result;
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public parseMFError(error: any): any {
        if (error) {
            return _.get(error, 'error.errors', []).map(er => {
                return er.msg;
            });
        }
        return '';
    }

    public checkStructError(data: ErrorResponse, code: string): boolean {
        const err = _.get(data, 'error.errors[0]', undefined);
        if (err) {
            if (err.code === code) {
                return true;
            }
        }
        return false;
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public openDocumentViewerOrDownload(data: any, type: any, filename: string, download: boolean): void {
        if (download) {
            FileSaver.saveAs(data, filename);
        } else {
            this.openDocumentViewer(data, type, filename);
        }
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public openDocumentViewerSimple(data: OpenDocumentRequest, filename: string, print?: boolean): Observable<any> {
        return this.openDocumentViewer(data.document, data.document.type, filename, print, data.signature);
    }

    public openDocumentViewer(
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: any,
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        type: any,
        filename: string,
        print?: boolean,
        signature?: DocumentSign,
        navigationButtons?: boolean,
        qrSettings?: { size: number; url: string },
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Observable<any> {
        if (type === 'application/pdf' || type.includes('image') || type == 'text/plain' || type.includes('video')) {
            const modalMedidaView = this.modalService.open(MediaViewPopupComponent, {
                size: 'lg',
                windowClass: 'mf-transparent-popup',
            });
            modalMedidaView.componentInstance.print = print;
            modalMedidaView.componentInstance.setdocumentFile = data;
            modalMedidaView.componentInstance.fileName = filename;
            modalMedidaView.componentInstance.documentType = type;
            modalMedidaView.componentInstance.signature = signature;
            modalMedidaView.componentInstance.navigationButtons = navigationButtons;
            // if (print) {
            //     modalMedidaView.componentInstance.printFile();
            // }

            return from(modalMedidaView.result);
        } else {
            //unknown type of document or qr code
            const modalMedidaView = this.modalService.open(MediaViewPopupComponent, {
                size: 'lg',
                windowClass: 'mf-transparent-popup',
            });
            modalMedidaView.componentInstance.fileName = filename;
            modalMedidaView.componentInstance.documentType = type;
            modalMedidaView.componentInstance.navigationButtons = navigationButtons;
            modalMedidaView.componentInstance.qrSettings = qrSettings;
            modalMedidaView.componentInstance.setdocumentFile = data;
            return from(modalMedidaView.result);
        }
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-empty-function
    public openQrCodePopup(data: any, type: any, filename: string) {}
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public openMultipleDocumentViewer(data: any, type: any, document: any, documents: any[]) {
        if (type === 'application/pdf' || type.includes('image')) {
            const modalMedidaView = this.modalService.open(MediaViewPopupComponent, {
                size: 'lg',
                windowClass: 'mf-transparent-popup',
            });
            modalMedidaView.componentInstance.setdocumentFile = data;
            modalMedidaView.componentInstance.fileName = document.description;
            modalMedidaView.componentInstance.documentType = type;
            modalMedidaView.componentInstance.action.subscribe(dataFromModal => {
                let index = 0;
                documents.forEach((el, ind) => {
                    if (JSON.stringify(el) === JSON.stringify(document)) {
                        index = ind;
                    }
                });
                if (dataFromModal.action === 'next') {
                    index++;
                    if (index == documents.length) {
                        index = 0;
                    }
                } else if (dataFromModal.action === 'previous') {
                    index--;
                    if (index < 0) {
                        index = documents.length - 1;
                    }
                }
            });

            if (print) {
                modalMedidaView.componentInstance.printFile();
            }

            return from(modalMedidaView.result);
        } else {
            console.log('Neznan format dokumenta, prenašam!');
            FileSaver.saveAs(data, document.description);
        }
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deepCopy(obj: any): any {
        if (obj) {
            return JSON.parse(JSON.stringify(obj));
        }
        return obj;
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getIconType(contentType: any): string {
        let fileName = '';
        if (contentType === 'application/pdf') {
            fileName = 'pdf';
        } else if (contentType === 'image/png') {
            fileName = 'png';
        } else if (contentType === 'image/jpeg' || contentType === 'image/jpg') {
            fileName = 'jpg';
        } else if (contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            fileName = 'doc';
        } else if (contentType === 'audio/ogg; codecs=opus' || contentType.includes('video')) {
            fileName = 'mp4';
        } else {
            fileName = 'any';
        }
        return './assets/app/media/img/files/' + fileName + '.svg';
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public parseMauticTaskObj(mautic: any): any {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: any = {};
        mautic.split('\n').forEach((el: string) => {
            if (el.includes('vase_ime_in_priimek')) {
                const tmp = el.split('->');
                data['leadName'] = tmp[1];
                return;
            }
            if (el.includes('vas_elektronski_naslov')) {
                const tmp = el.split('->');
                data['leadEmail'] = tmp[1];
                return;
            }
            if (el.includes('vas_telefon')) {
                const tmp = el.split('->');
                data['leadPhone'] = tmp[1];
                return;
            }
        });
        return data;
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getBusiness(bs: any): Business {
        //prejmeš Business objekt
        if (bs.bsid !== undefined) {
            return new Business().deserialize({
                bsid: bs.bsid,
                name: _.get(bs, 'name', 'Interno'),
                iconPath: _.get(bs, 'iconPath', '../../../assets/demo/demo5/media/img/logo/logo1.svg'),
            });
        }
        //prejmeš samo bsId ali drugače definiran Business
        let tmp = bs.id;
        if (tmp === undefined) {
            tmp = bs;
        }
        switch (tmp) {
            case 1:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: 'Generali',
                    iconPath: '../../../assets/app/media/img/bu/generali.svg',
                });
            case 2:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: 'Triglav',
                    iconPath: '../../../assets/app/media/img/bu/triglav.svg',
                });
            case 3:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: 'Merkur',
                    iconPath: '../../../assets/app/media/img/bu/merkur_2023.svg',
                });
            case 4:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: 'M zavarovalnica',
                    iconPath: '../../../assets/app/media/img/bu/medifit_icon.svg',
                });
            case 6:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: 'Doktor 24',
                    iconPath: '../../../assets/app/media/img/bu/logo_dr24.png',
                });
            case 7:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: 'Vzajemna',
                    iconPath: '../../../assets/app/media/img/bu/vzajemna.svg',
                });
            case 8:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: 'Medifit',
                    iconPath: '../../../assets/app/media/img/bu/medifit_icon.svg',
                });
            case 9:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: 'Sava',
                    iconPath: '../../../assets/app/media/img/bu/sava_icon.svg',
                });
            case 11:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: 'Zavarovalnica Generali',
                    iconPath: '../../../assets/app/media/img/bu/generali.svg',
                });
            case 12:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: 'Vox Medica',
                    iconPath: '../../../assets/app/media/img/bu/vox_medica.svg',
                });
            case 23:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: 'Vita',
                    iconPath: '../../../assets/app/media/img/bu/vita_icon.svg',
                });
            case 24:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: 'PRVA',
                    iconPath: '../../../assets/app/media/img/bu/prva.svg',
                });
            case 88:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: 'Medifit Medical',
                    iconPath: '../../../assets/app/media/img/bu/medifit_icon.svg',
                });
            case 111:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: 'Generali Pediater',
                    iconPath: '../../../assets/app/media/img/bu/generali.svg',
                });
            case -111:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: !_.isNil(bs.name) ? `${bs.name}` : 'Zunanja rezervacija',
                    iconPath: '../../../assets/demo/demo5/media/img/logo/logo1.svg',
                });
            default:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: !_.isNil(bs.name) ? `${bs.name}` : 'Interno',
                    iconPath: '../../../assets/demo/demo5/media/img/logo/logo1.svg',
                });
        }
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getApp(app: any): any {
        const tmp = app;
        switch (tmp) {
            case 'HDD':
            case 'HDP':
                return new App().deserialize({
                    app: app,
                    name: 'Halo Doktor',
                    iconPath: '../../../assets/app/media/img/hd/znak_grad-08.svg',
                    logoPath: '../../../assets/app/media/img/hd/Logo_HD_grad+gray.svg',
                });
            case 'SAVAD':
            case 'SAVAP':
                return new App().deserialize({
                    app: app,
                    name: 'Zdravnik na daljavo',
                    iconPath: '../../../assets/app/media/img/znd/zdravnik-na-daljavo-icon-color.svg',
                    logoPath: '../../../assets/app/media/img/znd/zdravnik-na-daljavo-color.svg',
                });
            case 'MDFTD':
            case 'MDFTP':
                return new App().deserialize({
                    app: app,
                    name: 'Medifit App',
                    iconPath: '../../../assets/app/media/img/bu/medifit_icon.svg',
                    logoPath: '../../../assets/app/media/img/logos/MEDIFIT_Logo-01.svg',
                });
            case 'LAIFD':
            case 'LAIFP':
                return new App().deserialize({
                    app: app,
                    name: 'Laif',
                    iconPath: '../../../assets/app/media/img/logos/laif_black.svg',
                    logoPath: '../../../assets/app/media/img/logos/laif_black.svg',
                });
            case 'WIDGET_VOUCHER':
                return new App().deserialize({
                    app: app,
                    name: 'Vavčer',
                    size: 24,
                    iconPath: './../../assets/demo/demo5/media/img/logo/logo1.svg',
                    logoPath: './../../assets/demo/demo5/media/img/logo/logo1.svg',
                });
            default:
                return new App().deserialize({
                    app: app,
                    name: 'Interno',
                    size: 24,
                    iconPath: '../../../assets/demo/demo5/media/img/logo/logo1.svg',
                    logoPath: '../../../assets/demo/demo5/media/img/logo/logo1.svg',
                });
        }
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public parseMauticTasksObjToString(mautic: any): string {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: any = this.parseMauticTaskObj(mautic);
        return `Naziv: ${data.leadName} \nEpošta: ${data.leadEmail} \nTelefon: ${data.leadPhone}`;
    }

    public replaceSlahsForDolar(data: string): string {
        if (!data) {
            return data;
        }
        return data.split('/').join('$$$');
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public filterArrayByCustomFilter(data: any[], filters: FilterElement[]) {
        filters = filters.filter(fil => fil.value !== '');
        data = data.filter(element => {
            return filters.every(fil => {
                if (fil.subtypes == undefined) {
                    if (_.isNil(element[fil.type])) {
                        return null;
                    }
                    return element[fil.type].toString().toLowerCase().indexOf(fil.value) !== -1 ? true : false;
                } else {
                    if (_.isNil(element[fil.type])) {
                        return false;
                    }
                    const filterString = JSON.stringify(
                        fil.subtypes.map(subfil => {
                            if (fil.caseElementType === 'object') {
                                if (_.isNil(element[fil.type][subfil])) {
                                    return null;
                                }
                                return element[fil.type][subfil].toString().toLowerCase();
                            } else if (fil.caseElementType === 'flat') {
                                if (_.isNil(element[subfil])) {
                                    return false;
                                }
                                return element[subfil].toString().toLowerCase();
                            } else {
                                return element[fil.type].map(el => {
                                    return el[subfil].toString().toLowerCase();
                                });
                            }
                        }),
                    );
                    const inputString: string[] = fil.value.toString().toLowerCase().split(' ');
                    return inputString.every(inputElement => {
                        return filterString.indexOf(inputElement) !== -1 ? true : false;
                    });
                }
            });
        });

        return data;
    }

    public loadCustomTags() {
        this.customTags = _.clone(tags.CUSTOM_TERMS_TAGS);
        const customTags = _.get(this.storage.getContractorSettings(), 'booking.customTags');
        if (customTags) {
            customTags.map(el => {
                this.customTags.push({
                    name: this.customTagPipe.transform(el.name),
                    id: el.id,
                    classColor: el.classColor,
                });
            });
        }
        return this.customTags;
    }

    public isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    public redirectToAndRefresh(uri: string) {
        this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => this.route.navigate([uri]));
    }

    public getDefaultCalendarFilterSettings(): CalendarUserGuiSettings {
        return new CalendarUserGuiSettings().deserialize({
            timeFrequency: 25,
            subconstractors: [],
            filter: new CalendarFilterSettings().deserialize({
                visible: true,
                showExternalAvailable: false,
                showExternalCustomerAvailable: false,
                showInternal: false,
                timeFrequncy: 25,
                showResources: false,
                showOnlyAvailable: false,
                showWeekend: false,
                showHolidays: false,
                show24h: false,
                showServicesColor: false,
                from: '06:00',
                to: '19:00',
            }),
        });
    }

    public stringDateToApiDate(date: string): string {
        const seperatedDate: string[] = date?.split('.');
        if (seperatedDate?.length == 3) {
            return `${seperatedDate[2]}-${seperatedDate[1]}-${seperatedDate[0]}`;
        }
        return date;
    }

    public apiDateTostringDate(date: string): string {
        const seperatedDate: string[] = date?.split('-');
        if (seperatedDate?.length == 3) {
            return `${seperatedDate[2]}.${seperatedDate[1]}.${seperatedDate[0]}`;
        }
        return date;
    }
    public getRouteForTodoElement(id: number): string {
        //TODO API KLIC ZA POSEBNE PRIMERE, npr: najdeš obstoječo avtorizacijo --> redirect
        switch (id) {
            case 1:
                return '/settings/profile';
            case 2:
                return '/settings/users';
            case 3:
                return '/settings/services';
            case 4:
                return '/settings/clinic';
            case 5:
                return '/settings/accounting';
            case 6:
                return '/settings/send-content';
            case 7:
                return '/settings/availability';
            case 8:
                return '/settings/printer';
            case 9:
                return '/customers';
            case 10:
                return '/calendar/main';
            case 11:
                return '/settings/partners';
            case 12:
                return '/accounting/list';
            case 13:
                return '/waiting-queue';
            case 14:
                return '/case';
            case 15:
                return '/treatments';
            case 16:
                return '/tasks';
            case 17:
                return '/communication';
            case 18:
                return '/reports';
            case 19:
                return '/marketing/inquiries';
            case 20:
                return '/inbox';
            default:
                return '/index';
        }
    }
    public getDefaultColorsForUsers(): string[] {
        return [
            '#CFD8DC',
            '#D1C4E9',
            '#B3E5FC',
            '#B2EBF2',
            '#DCEDC8',
            '#FFF9C4',
            '#FFECB3',
            '#FFCCBC',
            '#EEEEEE',
            '#F8BBD0',
            '#C5CAE9',
            '#BBDEFB',
            '#B2DFDB',
            '#C8E6C9',
            '#F0F4C3',
            '#FFE0B2',
            '#D7CCC8',
            '#CFD8DC',
        ];
    }
}
