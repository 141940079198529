import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { NgxAgoraService } from 'ngx-agora';
import { CallStateEnum } from '../call-state.enum';
import { WebSocketChannel } from '../model/webSocketChannel.model';
import { WebSocketChannelInfo } from '../model/webSocketChannelInfo.model';
import { TelemedicineService } from '../telemedicine.service';

@Component({
    selector: 'telemedicine-video',
    templateUrl: './video.component.html',
    styles: [],
})
export class VideoComponent implements OnInit, OnDestroy {
    //remoteCalls: string[] = [];
    //connected = false;
    //published = false;
    public localAvalaible = 'WAITING';
    public patientReady: { status: boolean; userData: WebSocketChannel } = { status: false, userData: null };
    public callState: CallStateEnum = CallStateEnum.NONE;
    public callStates = CallStateEnum;

    public audioDevices: MediaDeviceInfo[] = [];
    public videoDevices: MediaDeviceInfo[] = [];
    public playoutDevices: MediaDeviceInfo[] = [];

    public activeVideoDevice: MediaStreamTrack;
    public activeAudioDevice: MediaStreamTrack;
    public activePlayoutDevice: MediaDeviceInfo;

    public localAudioEnabled = true;
    public localVideoEnabled = true;

    public audioVolume = 0;

    public windowSize = 'modal';

    public showSettings = false;

    public outsideCallInProgress: WebSocketChannel = null;
    private outsideCallInProgress$;

    @ViewChild('videoModal', { read: ViewContainerRef, static: true })
    videoModal: ViewContainerRef;

    @ViewChild('remoteVideo', { read: ViewContainerRef, static: true })
    remoteVideo: ViewContainerRef;

    private remoteVideoCheck;
    private remoteVideoCheckCount = 0;

    public channelInfo: WebSocketChannelInfo = null;
    private channelInfo$;

    public callStartedOn: Moment = null;
    public previousCallLength = 0;
    public callDuration = {
        seconds: 0,
        minutes: 0,
        hours: 0,
        formatted: '',
    };

    public modalPosition = 'bottom-right';
    private posititonIndex = 0;
    constructor(
        private agoraService: NgxAgoraService,
        private telemedService: TelemedicineService,
    ) {}

    ngOnInit() {
        setInterval(() => {
            let seconds = 0;
            let minutes = 0;
            let hours = 0;
            let formatted = '';

            if (this.callStartedOn) {
                //const callStartedOn = this.callStartedOn.subtract(this.previousCallLength);
                const callStartedOn = this.callStartedOn;
                seconds = moment.duration(moment().diff(callStartedOn)).seconds();
                minutes = moment.duration(moment().diff(callStartedOn)).minutes();
                hours = moment.duration(moment().diff(callStartedOn)).hours();
                formatted =
                    (hours === 0 ? '' : (hours > 9 ? hours.toString() : '0' + hours) + ':') +
                    (minutes > 9 ? minutes : '0' + minutes).toString() +
                    ':' +
                    (seconds > 9 ? seconds : '0' + seconds).toString();
            }

            this.callDuration = {
                seconds: seconds,
                minutes: minutes,
                hours: hours,
                formatted: formatted,
            };
        }, 1000);

        this.telemedService.getLocalAvalaible().subscribe(avalaible => {
            this.localAvalaible = avalaible;
        });

        this.telemedService.getPatientReady().subscribe(ready => {
            this.patientReady = ready;
        });

        this.telemedService.getCallState().subscribe(callState => {
            if (callState === CallStateEnum.CALL_STARTED && this.callState !== callState) {
                //this.callStartedOn = moment();
                this.remoteVideoCheck = setInterval(() => {
                    const children = this.remoteVideo.element.nativeElement.children;
                    this.remoteVideoCheckCount++;
                    if (children.length > 0 && children[0].children.length > 0) {
                        clearInterval(this.remoteVideoCheck);
                        children[0].children[0].removeAttribute('controls');
                    }

                    if (this.remoteVideoCheckCount > 50) {
                        clearInterval(this.remoteVideoCheck);
                    }
                }, 300);
            }
            this.callState = callState;
        });

        this.telemedService.getActiveVideoDevice().subscribe(device => {
            this.activeVideoDevice = device;
        });

        this.telemedService.getActiveAudioDevice().subscribe(device => {
            this.activeAudioDevice = device;
        });

        this.telemedService.getActivePlayoutDevice().subscribe(device => {
            this.activePlayoutDevice = device;
        });

        this.telemedService.getLocalVideoEnabled().subscribe(enabled => {
            this.localVideoEnabled = enabled;
        });

        this.telemedService.getLocalAudioEnabled().subscribe(enabled => {
            this.localAudioEnabled = enabled;
        });

        this.telemedService.getAudioVolume().subscribe(volume => {
            this.audioVolume = volume;
        });

        this.telemedService.getWindowSize().subscribe(size => {
            this.windowSize = size;
        });

        //ngOnInit -> this.client.init
        this.telemedService.getCameras().subscribe(cameras => {
            this.videoDevices = cameras;
        });

        this.telemedService.getRecordingDevices().subscribe(devices => {
            this.audioDevices = devices;
        });

        this.telemedService.getPlayoutDevices().subscribe(devices => {
            this.playoutDevices = devices;
            if (!this.activePlayoutDevice && devices.length > 0 && devices[0].label !== '') {
                this.activePlayoutDevice = devices[0];
            }
        });

        this.outsideCallInProgress$ = this.telemedService.getOutsideCallInProgress().subscribe(inProgress => {
            this.outsideCallInProgress = inProgress;
        });

        this.channelInfo$ = this.telemedService.getChannelInfo().subscribe(channelInfo => {
            this.channelInfo = channelInfo;
            if (channelInfo && (channelInfo.doctorStartTime || channelInfo.patientStartTime)) {
                /*
                this.previousCallLength =
                    channelInfo.doctorSessionLength < channelInfo.patientSessionLength
                        ? channelInfo.patientSessionLength
                        : channelInfo.doctorSessionLength;
                        */
                this.callStartedOn =
                    channelInfo.doctorStartTime < channelInfo.patientStartTime ? channelInfo.patientStartTime : channelInfo.doctorStartTime;
            }
        });
    }

    ngOnDestroy(): void {
        if (this.remoteVideoCheck) {
            clearInterval(this.remoteVideoCheck);
        }
        this.closeCallWindow();
    }

    switchCamera(camera: MediaDeviceInfo) {
        if (this.videoDevices.length > 1 && camera.label !== this.activeVideoDevice.label) {
            this.telemedService.switchCamera(camera.deviceId);
        }
    }

    switchAudio(audio: MediaDeviceInfo) {
        if (this.audioDevices.length > 1 && audio.label !== this.activeAudioDevice.label) {
            this.telemedService.switchAudio(audio.deviceId);
        }
    }

    switchPlayoutDevice(device: MediaDeviceInfo) {
        if (this.playoutDevices.length > 1 && device.deviceId !== this.activePlayoutDevice.deviceId) {
            this.telemedService.switchPlayoutDevice(device);
        }
    }

    toggleSettings(forceTo: boolean = null) {
        if (forceTo !== null) {
            this.showSettings = forceTo;
        } else {
            this.showSettings = !this.showSettings;
        }
        if (this.showSettings === true && this.windowSize === 'small') {
            this.toggleModalSize('modal', true);
        }
        if (this.showSettings === false) {
            //this.telemedService.stopDemoSound();
        }
    }

    toggleVideo() {
        this.telemedService.toggleVideo();
    }

    toggleAudio() {
        this.telemedService.toggleAudio();
    }

    startCall() {
        this.telemedService.startCall();
    }

    closeCallWindow() {
        this.toggleSettings(false);
        this.telemedService.closeCallWindow();
    }

    setAudioVolume() {
        this.telemedService.setAudioVolume();
    }

    toggleAudioVolume() {
        console.log(this.audioVolume);
        if (this.audioVolume === 0) {
            this.telemedService.setAudioVolume(100);
        } else {
            this.telemedService.setAudioVolume(0);
        }
    }

    endCall() {
        this.toggleSettings(false);
        this.telemedService.endCall();
    }

    leaveCall() {
        this.toggleSettings(false);
        this.telemedService.leaveCall();
    }

    restartCall() {
        this.toggleSettings(false);
        this.telemedService.restartCall();
    }

    toggleFullScreen() {
        this.telemedService.toggleFullScreen(this.videoModal.element.nativeElement);
    }

    toggleModalSize(forceTo?: string, skipForceSettings: boolean = false) {
        if (!skipForceSettings) {
            this.toggleSettings(false);
        }
        this.telemedService.toggleModalSize(forceTo);
    }

    playDemoSound() {
        this.telemedService.playDemoSound();
    }

    public moveSmallVideo(): void {
        const positions: string[] = ['bottom-left', 'top-left', 'top-right', 'bottom-right'];
        this.modalPosition = positions[this.posititonIndex];
        if (this.posititonIndex == 3) {
            this.posititonIndex = 0;
        } else {
            this.posititonIndex++;
        }
        this.telemedService.toggleModalPosition(this.modalPosition);
    }
}
