import { HttpEventType, HttpResponse } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, filter, map, takeUntil } from 'rxjs/operators';
import { PortfolioPaper, Visit } from '../../../../../../../_shared/model';
import { DocumentSign } from '../../../../../../../_shared/model/portfolio/documentSign.model';
import { HelperService, MfToastService } from '../../../../../../../_shared/services';
import { ResultService } from '../../../../_services';
import { CustomerWorkDataDto } from './../../../../../../../_shared/model/customer/customer-work-data.model';
import { Reservation } from './../../../../../../../_shared/model/reservation.model';

// TODO Ignored with eslint-interactive on 2023-11-10
// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
export abstract class MdpsCertificateBase<T = any> {
    public documentName = 'Spričevalo';
    public paper: PortfolioPaper;
    protected visit: Visit;
    protected reservation: Reservation;
    protected fb: FormBuilder;
    protected form: FormGroup;
    protected resultService: ResultService;
    protected helper: HelperService;
    protected toast: MfToastService;
    protected onDestroy$: Subject<void>;
    protected customerWorkData: CustomerWorkDataDto;
    public calendarOptions: any;

    constructor(
        paper: PortfolioPaper,
        fb: FormBuilder,
        onDestroy$: Subject<void>,
        resultService: ResultService,
        helper: HelperService,
        toast: MfToastService,
        visit: Visit,
        reservation: Reservation,
    ) {
        this.paper = paper;
        this.fb = fb;
        this.onDestroy$ = onDestroy$;
        this.resultService = resultService;
        this.helper = helper;
        this.toast = toast;
        this.visit = visit;
        this.reservation = reservation;
        this.form = this.createForm();
        this.calendarOptions = { ...this.helper.getDateSettingsEmpty(), drops: 'up' };
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    abstract getPaperData<T>(paperId: number): void;
    abstract createForm(): FormGroup;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected abstract getPaperPreview$(): Observable<any>;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public abstract saveDocument$(): Observable<any>;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public abstract updateDocument$(): Observable<any>;

    public setCustomerWorkData(customerWorkData: CustomerWorkDataDto): void {
        this.customerWorkData = customerWorkData;
    }

    public openPreview(print?: boolean): void {
        this.getPaperPreview$()
            .pipe(
                takeUntil(this.onDestroy$),
                filter(res => res.type == HttpEventType.Response),
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                map((res: HttpResponse<any>) => {
                    const document: Blob = new Blob([res.body], {
                        type: 'application/pdf',
                    });

                    return { document: document, signature: this.getSignature(res) };
                }),
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                catchError(err => {
                    this.toast.errorAndSend('Napaka pri predogledu potrdila.');
                    return EMPTY;
                }),
            )
            .subscribe(({ document, signature }) => {
                if (document) {
                    this.helper.openDocumentViewer(document, document.type, this.documentName, print, signature);
                }
            });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getSignature(res: HttpResponse<any>): DocumentSign | null {
        const signer: string = res.headers.get('X-SIGNATURE-SIGNER');
        const date: string = res.headers.get('X-SIGNATURE-DATE');
        const contractor: string = res.headers.get('X-SIGNATURE-IZS');

        if (signer?.length) {
            return new DocumentSign().deserialize({
                signer: signer,
                date: date,
                contractor: contractor,
                appendTo: true,
            });
        }
        return null;
    }
}
