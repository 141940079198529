import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class DocumentNavigationService {
    // private hotKeys: Hotkey[] = [];
    // public jump: Subject<string> = new Subject();
    // constructor(private _hotkeysService: HotkeysService) {}
    // public documentPopupNavigation(fromUser?: boolean) {
    //     if (fromUser) {
    //         this.hotKeys.push(
    //             new Hotkey('left', (event: KeyboardEvent): boolean => {
    //                 this.jump.next('previous');
    //                 return false;
    //             }),
    //         );
    //         this.hotKeys.push(
    //             new Hotkey('right', (event: KeyboardEvent): boolean => {
    //                 this.jump.next('next');
    //                 return false;
    //             }),
    //         );
    //         this._hotkeysService.add(this.hotKeys);
    //     }
    // }
}
