import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { AuthenticationService } from './auth/_services';
import { Helpers } from './helpers';
import { TelephonyService } from './theme/pages/home/telephony/telephony.service';
import { ScriptLoaderService } from './_services/script-loader.service';
import { AppModulesType } from './_shared/enums/appModulesType.enum';
import { HelperService, MfToastService, RulesService, StorageService } from './_shared/services';
import { ActiveModulesService } from './_shared/services/activeModules.service';
import { OneSignalService } from './_shared/services/onesignal.service';
import { WebsocketActions } from './_shared/state/websocket/websocket.actions';

@Component({
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'body',
    templateUrl: './app.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
    private title = 'app';
    private globalBodyClassBase =
        'm-page--loading-non-block m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--fixed m-aside-left--offcanvas m-aside--offcanvas-default m-topbar--on';
    // 'm-page--loading-non-block m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default';
    private globalBodyClass = '';
    // SurveyType: typeof SurveyType = SurveyType;

    private isJumpPopupOpen = false;

    public isTelephonyModule: boolean = undefined;
    constructor(
        private _router: Router,
        public toastr: MfToastService,
        private rules: RulesService,
        private helper: HelperService,
        private _authService: AuthenticationService,
        private storage: StorageService,
        private oneSignal: OneSignalService,
        private store: Store,
        private activeModules: ActiveModulesService,
        private _script: ScriptLoaderService, // private _renderer: Renderer2, // @Inject(DOCUMENT) private _document,
        private telephony: TelephonyService,
    ) {
        // window['tinyMCE'].overrideDefaults({ base_url: '/assets/tinymce/', suffix: '.min' });
        this.toggleMenu();
    }
    private onDestroy$: Subject<void> = new Subject<void>();

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
    ngOnInit() {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this._script.loadScripts('body', ['assets/vendors/base/vendors.bundle.js', 'assets/demo/demo5/base/scripts.bundle.js'], true).then(result => {
            Helpers.setLoading(false);
        });
        this.toggleMenu();
        this._router.events.subscribe(route => {
            this.toggleMenu();

            if (route instanceof NavigationStart) {
                const curRoute = route.url?.split('/')[1];
                const isNotPublic = curRoute !== 'public';
                if (this.helper.isDifferentAppVersion()) {
                    if (isNotPublic) {
                        this.toastr.info('Naložene so bile posodobitve. Potrebna je ponovna prijava.');
                        this._authService.logout();
                        this._router.navigate(['/login']);
                    } else {
                        this._authService.logout();
                        // TODO Ignored with eslint-interactive on 2023-11-10
                        // eslint-disable-next-line no-self-assign
                        window.location = window.location;
                    }
                }
                Helpers.setLoading(true);
                // this.render.addClass(document.body, 'm-brand--minimize');
                Helpers.bodyClass(this.globalBodyClass);
            }
            if (route instanceof NavigationCancel) {
                Helpers.setLoading(false);
            }

            if (route instanceof NavigationEnd) {
                Helpers.setLoading(false);
            }
        });

        this.rules.setRules();
        // this.oneSignal.init();

        if (this.activeModules.isAM(AppModulesType.INBOX)) {
            this.store.dispatch(new WebsocketActions.InitWebSocket());
        }

        // .pipe(takeUntil(this.onDestroy$));
    }

    private toggleMenu(): void {
        if (_.get(this.storage.getGuiUserSettings(), 'app.isMenuMinimized', false)) {
            this.globalBodyClass = this.globalBodyClassBase + ' m-brand--minimize m-aside-left--minimize';
        } else {
            this.globalBodyClass = this.globalBodyClassBase;
        }
    }
}
